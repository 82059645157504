
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';

import { logout, mudarProcesso, changeState, mudarMensagens } from '../actions/AppActions';
import { connect } from 'react-redux';

import moment from 'moment-timezone';
import SweetAlert from 'react-bootstrap-sweetalert';
import { URL } from '../variables';
import Prova from './Prova';
import Redacao from './Redacao';
import Chat from './Chat';
import Questionario from './Questionario';
import { validate as isUuid } from 'uuid';

import axios from 'axios';
// import { DiffCamEngine, stopStreaming } from './DetectMovement';
import RequestUser from './RequestUser';
import { Buffer } from 'buffer';
import RequestAccepted from './RequestAccepted';

// import lz from 'lz-string';

class AvaliacaoAndamento extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',
            show_warning: false,
            loading_finish: false,
            tab: 0,
            title_warning: '',
            show_warning_page: false,
            last_warning: moment(),
            loading_pause: false,
            show_pause_modal: false,
            show_pause_modal_accepted: false,

            message_warning: 'Foi registrado que você saiu da página de avaliação',
            show_pause_timer: false,
            goTo:null
        };
        this.canvas = React.createRef();
        this.videoRef = React.createRef();
        this.videodata = []
        this.screendata = []
        this.selects_questions = {};


        this.last_date = moment().format('YYYY-MM-DD HH:mm:ss');
        this.last_date_request = moment().format('YYYY-MM-DD HH:mm:ss');


        this.time_text = '88:88:88';
        this.time_text_pause = '88:88:88';
        

        this.call_first = false;
        this.respostas = {}
        this.respostas_dissertativa = {};
        this.timer_pause_init = 20;
        this.umount=true;
        this.id='';
    }



    componentDidUpdate(prevProps) {
        // console.log('new');
        // console.log(prevProps.stream);
        // console.log(this.props.stream);

        if (this.props.stream != null && this.props.stream.id != prevProps.stream.id) {
            this.videoRef.current.srcObject = this.props.stream;
        }
        if (this.props.tempo_atual != null && this.props.tempo_atual != prevProps.tempo_atual) {
            this.seconds_diff = moment(this.props.tempo_atual).diff(moment(), 'seconds');
            this.seconds_diff_request = moment(this.props.tempo_atual).diff(moment(), 'seconds');
        }


        if (this.fim != this.props.processo.fim) {
            this.fim = this.props.processo.fim;
        }

        if (this.props.processo.simulado==false && this.props.stream_screen != null && this.mediaRecorder_2 == null && this.props.processo.screen_sharing == true && this.call_first == false) {
            this.init_media_recorder_screen(this.id);
        }

        if(prevProps.socket==null && this.props.socket!=null){
            try{
            this.props.socket.on("request_pause", (data) => {
                console.log('request pause', data);
                if (data.type == 'request') {
                    this.props.changeState({ request_user: data });
                }
                else if (data.type == 'response') {
                    if (data.approved == false) {
                        this.props.changeState({ request_user: data });

                        this.setState({ show_warning_page: true, loading_pause: false, message_warning: 'Sua solicitação de pausa foi negada pelo fiscal' });
                    }
                    else if (data.approved == true && this.props.request_user.pause_end == null) {
                        if(data.updated_at!=this.props.request_user.updated_at){
                            this.setState({ show_pause_modal_accepted: true,loading_pause:false, request_user: data });
                        }
                        else{
                            console.log('nao é igual')
                            console.log({...this.props.request_user})
                            this.setState({loading_pause:false})
                        }
                        this.props.changeState({ request_user: data });

                        // if(data.started==null){
                        //     this.timerPause();
                        // }
                    }
                }
                clearTimeout(this.waitResponsePause);
            });

            this.props.socket.on("finish_event", (data) => {
                console.log('finish_event',data);
                if(this.props.processo.is_interview && data.processo_id==this.props.processo.id){

                    this.props.get_processo();
                }
            });

            if(this.props.user.accessibility && this.props.user.type_supervisor!='none' && this.props.processo.is_interview==false){
                console.log('montou',this.props.user)
                this.props.changeState({show_call:true});
            }
        }
        catch(e){
            
        }
        }


        // if (prevProps.processo.movement != this.props.processo.movement && this.props.processo.movement == true && this.props.processo.type_fiscalization != 'none') {
        //     try {
        //         if (DiffCamEngine.getInit() == false) {
        //             console.log('iniciando');
        //             // DiffCamEngine.init({
        //             //     video: document.getElementById('video_oficial'),
        //             //     movementCallBack: (score) => this.movementDetect(score),
        //             //     scoreThreshold: 400
        //             //     // motionCanvas: document.getElementById('motion'),
        //             // });
        //         }


        //     }
        //     catch (e) {
        //         console.log(e);
        //     }
        // }
        // else if (prevProps.processo.movement != this.props.processo.movement && this.props.processo.movement == false) {
        //     try {
        //         stopStreaming();


        //     }
        //     catch (e) {
        //         console.log(e);
        //     }
        // }


    }

    incTimer() {
        // var currentMinutes = Math.floor(totalSecs / 60);
        let last_date_new = moment().format('YYYY-MM-DD HH:mm:ss');

        let diff = moment().tz('America/Sao_Paulo').diff(this.last_date, 'seconds');
        this.last_date = last_date_new;

        if (Math.abs(diff) >= 2) {

            this.seconds_diff = this.seconds_diff + (-1 * diff) + 1;

        }

        var totalSecs = moment(this.fim, 'YYYY-MM-DD HH:mm:ss').diff(moment().add(this.seconds_diff, 'seconds').format('YYYY-MM-DD HH:mm:ss'), 'seconds');
        var currentHours = Math.floor((totalSecs / 60) / 60);
        var currentMinutes = Math.floor(totalSecs / 60) - currentHours * 60

        var currentSeconds = totalSecs % 60;
        if (currentSeconds <= 9) currentSeconds = "0" + currentSeconds;
        if (currentMinutes <= 9) currentMinutes = "0" + currentMinutes;
        if (currentHours <= 9) currentHours = "0" + currentHours;
        if (document.getElementById('timer') == null) {
            return;
        }
        if (totalSecs <= 0) {
            totalSecs = 0;
            this.time_text = "00:00:00";
            document.getElementById('timer').innerHTML = 'Tempo restante: 00:00:00';
            this.isFinish=true;

            this.props.get_processo();
        }
        else {
            this.time_text = currentHours + ":" + currentMinutes + ":" + currentSeconds;
            document.getElementById('timer').innerHTML = 'Tempo restante: ' + currentHours + ":" + currentMinutes + ":" + currentSeconds;
            // console.log('Tempo restante: '+currentHours + ":" + currentMinutes + ":" + currentSeconds);
            // console.log(this.time_text);

            this.timer_timeout = setTimeout(function () {
                this.incTimer()
            }.bind(this), 1000);
        }
    }

    incTimerRequest() {
        console.log('request', { ...this.props.request_user })
        if (this.props.request_user == null || this.props.request_user.started == null) {
            return;
        }
        // var currentMinutes = Math.floor(totalSecs / 60);
        let last_date_new_request = moment().format('YYYY-MM-DD HH:mm:ss');

        let diff = moment().tz('America/Sao_Paulo').diff(this.last_date_request, 'seconds');
        console.log(last_date_new_request,this.last_date_request,diff)

        this.last_date_request = last_date_new_request;

        if (Math.abs(diff) >= 2) {

            this.seconds_diff_request = this.seconds_diff_request + (-1 * diff) + 1;

        }
        console.log(this.props.request_user.started)
        console.log(this.props.request_user.time)
        console.log(this.seconds_diff_request)

        
        var totalSecs = moment(this.props.request_user.started, 'YYYY-MM-DD HH:mm:ss').add('seconds', this.props.request_user.time).diff(moment().add(this.seconds_diff_request, 'seconds').format('YYYY-MM-DD HH:mm:ss'), 'seconds');
        var currentHours = Math.floor((totalSecs / 60) / 60);
        var currentMinutes = Math.floor(totalSecs / 60) - currentHours * 60
        console.log(totalSecs)
        var currentSeconds = totalSecs % 60;
        if (currentSeconds <= 9) currentSeconds = "0" + currentSeconds;
        if (currentMinutes <= 9) currentMinutes = "0" + currentMinutes;
        if (currentHours <= 9) currentHours = "0" + currentHours;
        if (document.getElementById('timer_request') == null) {
            return;
        }
        if (totalSecs <= 0) {
            totalSecs = 0;
            this.time_text_pause = "00:00:00";
            document.getElementById('timer_request').innerHTML = 'Tempo restante de pausa: 00:00';

            // this.props.get_processo();
            this.setState({ pause_end: true });
        }
        else {
            this.time_text_pause = + currentMinutes + ":" + currentSeconds;
            document.getElementById('timer_request').innerHTML = 'Tempo restante de pausa: ' + currentMinutes + ":" + currentSeconds;
            // console.log('Tempo restante: '+currentHours + ":" + currentMinutes + ":" + currentSeconds);
            // console.log(this.time_text);

            this.timer_timeout_request = setTimeout(function () {
                this.incTimerRequest();
            }.bind(this), 1000);
        }
    }

    send_status() {
        if (this.videoRef.current == null) {
            console.log('Status not send 1');

            return;
        }
        if (this.props.socket != null) {
            console.log('Status send');
            this.props.socket.emit("send_status", { aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id });
            setTimeout(() => {
                this.send_status();
            }, 60000);
        }
        else {
            console.log('Status not send 2');

        }
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    async salvar_log(event) {
        let event_m = '';
        if (event.type == 'blur') {
            if (moment().diff(this.state.last_warning, 'seconds') > 30 && this.props.processo.type_fiscalization == 'all') {
                this.setState({ show_warning_page: true, last_warning: moment(), message_warning: 'Foi registrado que você saiu da página de avaliação' });
            }
            event_m = 'desfocou';
            if (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') {
                if (this.props.socket != null) {

                    this.props.socket.emit("send_warning_behavior", { processo_id: this.props.processo.id, msg: 'O candidato saiu da página da avaliação', sala_id: this.props.sala.id, aluno_id: this.props.user.id, nome_chat: this.props.user.name, id_msg: this.uuidv4(), faculdade_id: this.props.faculdade.id, type: 'out' });
                }
            }

        }
        else {
            event_m = 'focou';
            if (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') {
                if (this.props.socket != null) {

                    this.props.socket.emit("send_warning_behavior", { processo_id: this.props.processo.id, msg: 'O candidato voltou para a página da avaliação', sala_id: this.props.sala.id, aluno_id: this.props.user.id, nome_chat: this.props.user.name, id_msg: this.uuidv4(), faculdade_id: this.props.faculdade.id, type: 'in' });
                }
            }
        }


        fetch(`${URL}api/salvar_log?screen=${event_m}&processo_id=${this.props.processo.id}&processo_name=${this.props.processo.name}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    if (resp.status == false) {
                        this.props.get_processo();
                    }
                    else {
                        if (resp.processo != null && (resp.processo.chat_type != this.props.processo.chat_type || resp.processo.updated_at != this.props.processo.updated_at)) {
                            this.props.changeState({ processo: resp.processo });
                        }
                        if (resp.atual != undefined) {
                            this.seconds_diff = moment(resp.atual).diff(moment(), 'seconds');
                            this.seconds_diff_request = moment(resp.atual).diff(moment(), 'seconds');
                            this.props.changeState({ tempo_atual: resp.atual });
                        }
                    }

                }
            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }


    async salvar_log_warning(event_warning, number_aba) {

        fetch(`${URL}api/salvar_log?screen=${event_warning}&processo_id=${this.props.processo.id}&processo_name=${this.props.processo.name}&msg=${number_aba}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    if (resp.status == false) {
                        this.props.get_processo();
                    }
                    else {
                        if (resp.processo != null && (resp.processo.chat_type != this.props.processo.chat_type || resp.processo.updated_at != this.props.processo.updated_at)) {
                            this.props.changeState({ processo: resp.processo });
                        }
                    }

                }
            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    componentDidMount() {

        console.log('montou')
        this.isFinish=false;

        if(this.umount==true){
        this.id=this.uuidv4();
            this.umount=false;
        this.props.changeState({ andamento: true });
        // if (this.props.processo.movement == true && this.props.processo.type_fiscalization != 'none') {
        //     try {
        //         if (DiffCamEngine.getInit() == false) {
        //             console.log('iniciando');
        //             // DiffCamEngine.init({
        //             //     video: document.getElementById('video_oficial'),
        //             //     movementCallBack: (score) => this.movementDetect(score),
        //             //     scoreThreshold: 400
        //             //     // motionCanvas: document.getElementById('motion'),
        //             // });
        //         }


        //     }
        //     catch (e) {
        //         console.log(e);
        //     }
        // }

        if (this.props.processo.simulado == false) {
            this.salvar_log_bind = this.salvar_log.bind(this);
            window.addEventListener('focus', this.salvar_log_bind);
            window.addEventListener('blur', this.salvar_log_bind);
        }
        else {
            this.lineCheckInterval = setInterval(() => {
                if (this.props.socket != null) {
                    this.props.socket.emit("line_check", { aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id, enter_now: true });
                }
            }, 30000);
        }

        if (this.props.request_user != null) {
            if (this.props.request_user.approved == null) {
                this.setState({ loading_pause: true })
            }
            else if (this.props.request_user.approved == true && this.props.request_user.pause_end == null) {
                if(this.props.request_user.started==null){
                    this.setState({show_pause_modal_accepted:true})
                }
                else{
                    this.setState({ show_pause_timer: true },() => {
                        clearTimeout(this.timer_timeout_request)
                        this.last_date_request = moment().format('YYYY-MM-DD HH:mm:ss');

                        this.incTimerRequest();
                    });
                }
            }

        }
        this.seconds_diff = moment(this.props.tempo_atual).diff(moment(), 'seconds');
        this.seconds_diff_request = moment(this.props.tempo_atual).diff(moment(), 'seconds');

        this.fim = this.props.processo.fim;
        console.log('INICIOU TIMER');
        this.incTimer();
        this.options = {
            // mimeType:this.props.processo.mobile==true?'video/webm': "video/webm;codecs=vp8,vp9,opus",
            mimeType: this.props.processo.mobile == true ? 'video/webm' : "video/webm;codecs=h264",
            audioBitsPerSecond: 4000,
            videoBitsPerSecond: 500,
            bitsPerSecond: 500
        };
        this.options_screen = {
            // mimeType:this.props.processo.mobile==true?'video/webm': "video/webm;codecs=vp8,vp9,opus",
            mimeType: this.props.processo.mobile == true ? 'video/webm' : "video/webm;codecs=h264",
            videoBitsPerSecond: 50000,
            bitsPerSecond: 50000
        };
        console.log(this.options);
        if (this.props.processo.type_fiscalization == 'all') {

            this.videoRef.current.srcObject = this.props.stream;
            if (this.props.processo.simulado == false) {
                this.props.getValues()
                this.init_media_recorder(this.id);

                this.init_media_recorder_screen(this.id);
            }




            setTimeout(() => {
                this.send_status();
            }, 60000);


        }
        else if (this.props.processo.type_fiscalization == 'image') {
            // this.timeout_save_logs_and_wording = setTimeout(() => {
            //     this.save_logs_and_wording();
            // }, 30000);
            this.timer_save = !this.props.processo.save_timer || this.props.processo.save_timer<30000?30000:this.props.processo.save_timer;
            setTimeout(() => {
                this.send_status();
            }, 60000);

            this.videoRef.current.srcObject = this.props.stream;
            if (this.props.processo.simulado == false) {

                this.timeout_save_logs_and_wording = setTimeout(() => {
                    this.save_logs_and_wording();
                }, this.timer_save);
            }
        }
        else {
            this.timer_save = !this.props.processo.save_timer || this.props.processo.save_timer<30000?30000:this.props.processo.save_timer;
            if (this.props.processo.simulado == false) {

                this.timeout_save_logs_and_wording = setTimeout(() => {
                    this.save_logs_and_wording();
                }, this.timer_save);
            }
        }
        setTimeout(() => {
            this.get_time_server();
        }, 30000);

        if (this.props.socket != null) {
            console.log('entrouuuu')
            this.props.socket.on("request_pause", (data) => {
                console.log('request pause', data);
                if (data.type == 'request') {
                    this.props.changeState({ request_user: data });
                }
                else if (data.type == 'response') {
                    if (data.approved == false) {
                        this.props.changeState({ request_user: data });

                        this.setState({ show_warning_page: true, loading_pause: false, message_warning: 'Sua solicitação de pausa foi negada pelo fiscal' });
                    }
                    else if (data.approved == true && this.props.request_user.pause_end == null) {
                        if(data.updated_at!=this.props.request_user.updated_at){
                            this.setState({ show_pause_modal_accepted: true,loading_pause:false, request_user: data });
                        }
                        else{
                            console.log('nao é igual')
                            console.log({...this.props.request_user})
                            this.setState({loading_pause:false})
                        }
                        this.props.changeState({ request_user: data });

                        // if(data.started==null){
                        //     this.timerPause();
                        // }
                    }
                }
                clearTimeout(this.waitResponsePause);
            });

            this.props.socket.on("finish_event", (data) => {
                console.log('finish_event',data);
                if(this.props.processo.is_interview && data.processo_id==this.props.processo.id){

                    this.props.get_processo();
                }
            });

            if(this.props.user.accessibility && this.props.user.type_supervisor!='none' && this.props.processo.is_interview==false){
                console.log('montou',this.props.user)
                this.props.changeState({show_call:true});
            }

        }

        if (this.props.processo.exam_per_time == true) {
            let filtered = [...this.props.questionarios, ...this.props.provas, ...this.props.redacoes].map((item, id) => {
                let return_value = false;
                if (item.prova != undefined) {
                    let questoes_number = item.questoes.map((item, id) => {
                        if (item.type_questao == 'conjunto') {
                            return item.questoes.length;
                        }
                        else {
                            return 1;
                        }
                    }).reduce((a, b) => a + b, 0);

                    if (item.order != null && questoes_number < item.order.select_question) {
                        return_value = true
                    }


                }
                else {
                    if (item.resultado.finish == true) {
                        return_value = true;
                    }
                }

                return return_value;

            });
            console.log(filtered)
            let index_filter = filtered.indexOf(false);
            if (index_filter == -1) {
                index_filter = filtered.length - 1;
            }
            console.log('tab', index_filter);
            this.setState({ tab: index_filter });
        }/////
    }

    }

    movementDetect(score) {
        console.log(score)
        this.props.socket.emit("send_warning_movement", { sala_id: this.props.sala.id, aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id });
        this.salvar_log_warning('movement', 'Movimento detectado. Score: ' + score);
    }

    componentWillUnmount() {
        console.log('desmontou')
        if(this.isFinish!=true){
            this.save_logs_and_wording(false)
        }
        else{
            this.props.changeStateParent({show_face_mesh:false});
        }
//
        this.umount=true;
        if(this.mediaRecorder){
            try{
                this.mediaRecorder.stop()
            }catch(e){

            }
        }
        if(this.mediaRecorder_2){
            try{
                this.mediaRecorder_2.stop()
            }catch(e){

            }
        }
        this.props.changeState({ andamento: false, show_call: false });
        if (this.props.processo.simulado == false) {
            window.removeEventListener('focus', this.salvar_log_bind);
            window.removeEventListener('blur', this.salvar_log_bind);
        }
        clearTimeout(this.timer_timeout);
        clearTimeout(this.timer_timeout_request);

        clearTimeout(this.timeout_save_logs_and_wording);
        clearInterval(this.lineCheckInterval);
        clearInterval(this.timeout_pause);
        clearTimeout(this.timeout_pause_init)

        

    }


    get_time_server() {
        if (this.videoRef.current == null) {
            return;
        }
        fetch(`${URL}api/get_time_server`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                else {
                    if (resp.atual != null) {
                        this.seconds_diff = moment(resp.atual).diff(moment(), 'seconds');
                        this.seconds_diff_request = moment(resp.atual).diff(moment(), 'seconds');

                        this.props.changeState({ tempo_atual: resp.atual });
                    }
                }
                setTimeout(() => {
                    this.get_time_server();
                }, 30000);
            } catch (err) {
                console.log(err);
                setTimeout(() => {
                    this.get_time_server();
                }, 30000);
            }

        })
            .catch((err) => {
                console.log(err);
                setTimeout(() => {
                    this.get_time_server();
                }, 30000);
            });
    }

    save_logs_and_wording(timeout_send=true) {
        let textos = [];
        let titulos = [];
        let redacao_ids = [];
        for (let i = 0; i < this.props.redacoes.length; i++) {
            textos.push(this.props.redacoes[i].resultado.texto);
            titulos.push(this.props.redacoes[i].resultado.titulo);
            redacao_ids.push(this.props.redacoes[i].redacao.id);
        }

        let form = new FormData();
        if (redacao_ids.length > 0) {
            for (let i = 0; i < redacao_ids.length; i++) {
                form.append('textos[]', textos[i]);
                form.append('titulos[]', titulos[i]);
                form.append('redacao_ids[]', redacao_ids[i]);
            }
        }
        form.append('processo_id', this.props.processo.id);
        if (Object.values(this.respostas).length > 0) {
            form.append('respostas', JSON.stringify(this.respostas))
        }

        if ((this.props.processo.type_fiscalization == 'image')) {
            if (document.getElementById('video_oficial') == null) {
                this.props.history.push({
                    pathname: '/',
                    search: '?error=Seu vídeo deve estar compartilhado para fazer a avaliação'
                });
                return;
            }
            this.canvas.current.height = document.getElementById('video_oficial').videoHeight;
            this.canvas.current.width = document.getElementById('video_oficial').videoWidth;
            this.canvas.current.getContext('2d').drawImage(document.getElementById('video_oficial'), 0, 0);
            form.append('foto', this.canvas.current.toDataURL('image/jpeg', 0.15));

        }


        fetch(`${URL}api/save_logs_and_wording`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    if (resp.status == false || resp.message == 'Server Error') {
                        this.props.get_processo();
                    }
                    else if (resp.processo == null) {
                        this.props.changeState({ processo: null, stream: null, stream_screen: null });
                    }
                    else if(timeout_send==true) {
                        let data_request = {};
                        if (resp.request_user != null && resp.request_user.updated_at != this.props.request_user?.updated_at) {
                            data_request = {
                                request_user: resp.request_user
                            };
                            if (resp.request_user.approved == null) {
                                this.setState({ loading_pause: true })
                            }
                            else if (resp.request_user.approved == true && resp.request_user.pause_end == null) {
                                if(resp.request_user.started==null){
                                    this.setState({ show_pause_modal_accepted: true });
                                }
                                else{
                                    this.setState({ show_pause_timer: true },()=>{
                                        clearTimeout(this.timer_timeout_request)
                        this.last_date_request = moment().format('YYYY-MM-DD HH:mm:ss');

                                        this.incTimerRequest()
                                    });
                                }
                            }
                        }
                        if (resp.processo != null && (resp.processo.chat_type != this.props.processo.chat_type || resp.processo.full_screen != this.props.processo.full_screen || this.props.processo.fim != resp.processo.fim || resp.processo.updated_at != this.props.processo.updated_at || data_request.request_user != undefined)) {
                            this.props.changeState({ processo: resp.processo, ...data_request });
                            this.fim = resp.processo.fim;
                        }

                        this.timeout_save_logs_and_wording = setTimeout(() => {
                            this.save_logs_and_wording(timeout_send);
                        }, this.timer_save);
                    }


                }




            } catch (err) {
                console.log(err);
                this.timeout_save_logs_and_wording = setTimeout(() => {
                    this.save_logs_and_wording(timeout_send);
                }, this.timer_save);

            }

        })
            .catch((err) => {

                console.log(err);
                this.timeout_save_logs_and_wording = setTimeout(() => {
                    this.save_logs_and_wording(timeout_send);
                }, this.timer_save);
                // this.props.mudarLoadingHome(false);
            });
    }


    pause_status(type = 'start') {

        fetch(type == 'start' ? `${URL}api/request_start` : `${URL}api/request_end`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: this.props.request_user?.id,
                processo_id: this.props.processo?.id,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    if (resp.status == false || resp.message == 'Server Error') {
                        this.timeout_pause = setTimeout(() => {
                            this.pause_status(type);
                        }, 5000);
                    }
                    else if (resp.processo == null) {
                        this.props.changeState({ processo: null, stream: null, stream_screen: null });
                    }
                    else {
                        let data_request = {};
                        if (resp.request_user != null && resp.request_user.updated_at != this.props.request_user?.updated_at) {
                            data_request = {
                                request_user: resp.request_user
                            };
                        }
                        if (resp.processo != null && (resp.processo.chat_type != this.props.processo.chat_type || resp.processo.full_screen != this.props.processo.full_screen || this.props.processo.fim != resp.processo.fim || resp.processo.updated_at != this.props.processo.updated_at || data_request.request_user != undefined)) {
                            this.props.changeState({ processo: resp.processo, ...data_request });
                            this.fim = resp.processo.fim;
                        }
                        if (type == 'start') {
                            console.log('iniciou timer')
                            let provas = [...this.props.provas];
                            provas = provas.map((item)=>{
                                resp.blockeds.map((b)=>{
                                    if(b.prova_id==item.prova.id){
                                        item.blockeds=b;
                                    }
                                })
                                return item;
                            })
                            this.setState({ loading_pause_response: false,show_pause_timer:true }, () => {
                                clearTimeout(this.timer_timeout_request)
                        this.last_date_request = moment().format('YYYY-MM-DD HH:mm:ss');

                                this.incTimerRequest();
                            });
                        }
                        else {
                            this.setState({ loading_pause_response: false, show_pause_timer: false, loading_pause: false })
                        }



                    }


                }




            } catch (err) {
                console.log(err);
                this.timeout_pause = setTimeout(() => {
                    this.pause_status(type);
                }, 5000);

            }

        })
            .catch((err) => {

                console.log(err);
                this.timeout_pause = setTimeout(() => {
                    this.pause_status(type);
                }, 5000);
                // this.props.mudarLoadingHome(false);
            });
    }



    init_media_recorder(id) {
        this.mediaRecorder = new MediaRecorder(this.props.stream, this.options);

        this.mediaRecorder.ondataavailable = function (e) {
            this.videodata.push(e.data);
        }.bind(this);
        let id_stream = this.props.stream.id;
        this.mediaRecorder.onstop = async function (e) {
            console.log(e);
            clearTimeout(this.timeout_stop_media);
            if(id==this.id && this.umount==false){
                this.salvar_dados(id_stream,id);
            }
        }.bind(this);
        this.mediaRecorder.start();
        this.timeout_stop_media= setTimeout(() => {
            if (this.mediaRecorder.state != 'inactive') {
                // console.log('stopppp')
                this.mediaRecorder.stop();
            }

        }, !this.props.processo.save_timer || this.props.processo.save_timer<30000?30000:this.props.processo.save_timer);



    }



    init_media_recorder_screen(id) {
        this.call_first = true;
        if (this.props.processo.screen_sharing == true && this.props.stream_screen != null) {
            this.mediaRecorder_2 = new MediaRecorder(this.props.stream_screen, this.options_screen);
            this.mediaRecorder_2.ondataavailable = function (e) {
                this.screendata.push(e.data);

            }.bind(this);
            this.mediaRecorder_2.onstop = async function (e) {
                console.log(e);
                clearTimeout(this.timeout_stop_screen)
                if(id==this.id && this.umount==false){
                    this.salvar_screen(id);
                }
            }.bind(this);
            this.mediaRecorder_2.start();
            this.timeout_stop_screen = setTimeout(() => {
                if (this.mediaRecorder_2.state != 'inactive') {
                    this.mediaRecorder_2.stop();
                }

            }, !this.props.processo.save_timer || this.props.processo.save_timer<30000?30000:this.props.processo.save_timer);
        }
        else {
            this.mediaRecorder_2 = null;
        }


    }

    async salvar_screen(id) {
        if (this.videoRef.current == null || this.umount==true || this.id!=id) {
            return;
        }
        this.init_media_recorder_screen(id);




        // this.mediaRecorder.requestData();


        let date_tz = moment().add(this.seconds_diff, 'seconds').format('YYYY-MM-DD_HH-mm-ss');
        let form = new FormData();
        form.append('processo_id', this.props.processo.id);
        form.append('date', date_tz);

        let screenBlob = null;
        screenBlob = new Blob(this.screendata, { type: "video/webm;codecs=h264" });


        // const jsZip = new JSZip();
        // jsZip.file('video_' + date_tz + '.webm', recordedBlob);
        // let screenCaptureZipFile = await jsZip.generateAsync({
        //     type: 'blob',
        //     compression: 'DEFLATE',
        //     compressionOptions: {
        //         level: 9
        //     }
        // });
        this.screendata = []

        // lz.compress(recordedBlob.)
        // var reader = new FileReader();
        // reader.readAsDataURL(blob); 
        // reader.onloadend = function() {
        // var base64data = reader.result;                
        //     console.log(base64data);
        // }


        fetch(`${URL}api/salvar_screen`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    if (resp.status == false || resp.message == 'Server Error') {
                        this.props.get_processo();
                    }
                    else if (resp.processo == null) {
                        this.props.changeState({ processo: null, stream: null, stream_screen: null });
                    }
                    else {

                        if (resp.processo != null && (resp.processo.chat_type != this.props.processo.chat_type || resp.processo.updated_at != this.props.processo.updated_at || this.props.processo.fim != resp.processo.fim)) {
                            this.props.changeState({ processo: resp.processo });
                            this.fim = resp.processo.fim;
                        }

                        if (resp.url_screen != undefined && screenBlob != null) {
                            axios.put(resp.url_screen, screenBlob, {
                                headers: {
                                    "Content-Type": screenBlob.type
                                }
                            }).then((resp) => {
                                console.log(resp)
                            }).catch((e) => {
                                console.log(e);
                            });
                        }

                    }
                }




            } catch (err) {
                console.log(err);


            }

        })
            .catch((err) => {

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    async salvar_dados(id_stream,id) {
        if (this.videoRef.current == null || this.umount==true || this.id!=id) {
            return;
        }
        let values = this.props.getValues();
        // console.log('Diferença stream: ', this.props.stream.id != id_stream)
        console.log(values);
        this.init_media_recorder(id)




        // this.mediaRecorder.requestData();
        if (document.getElementById('video_oficial') == null) {
            this.props.history.push({
                pathname: '/',
                search: '?error=Seu vídeo deve estar compartilhado para fazer a avaliação'
            });
            return;
        }
        this.canvas.current.height = document.getElementById('video_oficial').videoHeight;
        this.canvas.current.width = document.getElementById('video_oficial').videoWidth;
        // console.log(this.canvas.current.width,this.canvas.current.height)

        this.canvas.current.getContext('2d').drawImage(document.getElementById('video_oficial'), 0, 0);

        // this.aceitar_regras(this.canvas.current.toDataURL('image/jpeg',0.15));
        let textos = [];
        let titulos = [];
        let redacao_ids = [];
        for (let i = 0; i < this.props.redacoes.length; i++) {
            textos.push(this.props.redacoes[i].resultado.texto);
            titulos.push(this.props.redacoes[i].resultado.titulo);
            redacao_ids.push(this.props.redacoes[i].redacao.id);
        }

        console.log(redacao_ids);

        let provas = {};

        for (let i = 0; i < this.props.provas.length; i++) {
            if (provas[this.props.provas[i].prova.id] == undefined) {
                provas[this.props.provas[i].prova.id] = {}
            }
            let questoes = Object.keys(this.props.provas[i].respostas);
            for (let j = 0; j < questoes.length; j++) {
                provas[this.props.provas[i].prova.id][questoes[j]] = this.props.provas[i].respostas[questoes[j]];
            }
        }
        let date_tz = moment().add(this.seconds_diff, 'seconds').format('YYYY-MM-DD_HH-mm-ss');
        let form = new FormData();
        if (redacao_ids.length > 0) {
            for (let i = 0; i < redacao_ids.length; i++) {
                form.append('textos[]', textos[i]);
                form.append('titulos[]', titulos[i]);
                form.append('redacao_ids[]', redacao_ids[i]);
            }
        }

        form.append('tempo', this.time_text);
        form.append('tab', this.state.tab);
        form.append('processo_id', this.props.processo.id);
        form.append('profile', 'jpeg');
        form.append('date', date_tz);
        form.append('scroll_position', window.scrollY);
        form.append('width_window', window.innerWidth);
        form.append('height_window', window.innerHeight);
        form.append('video', 'video');

        // form.append('provas', JSON.stringify(provas));
        if (Object.values(this.respostas).length > 0) {
            form.append('respostas', JSON.stringify(this.respostas))
        }
        // let recordedBlob = new Blob(this.videodata, { type: "video/webm;codecs=vp8,vp9,opus" });
        let recordedBlob = new Blob(this.videodata, { type: "video/webm;codecs=h264" });

        // let screenBlob = null;
        // if (this.props.processo.screen_sharing == true && this.screendata.length > 0) {
        //     screenBlob = new Blob(this.screendata, { type: "video/webm;codecs=h264" });
        // }


        // const jsZip = new JSZip();
        // jsZip.file('video_' + date_tz + '.webm', recordedBlob);
        // let screenCaptureZipFile = await jsZip.generateAsync({
        //     type: 'blob',
        //     compression: 'DEFLATE',
        //     compressionOptions: {
        //         level: 9
        //     }
        // });
        form.append('center_count', values.center_count);
        form.append('down_count', values.down_count);
        form.append('face_count', values.face_count);
        form.append('frame_count', values.frame_count);
        form.append('left_count', values.left_count);
        form.append('right_count', values.right_count);
        form.append('up_count', values.up_count);
        form.append('multiface_count', values.multiface_count);
        form.append('middle_count', values.middle_count);



        console.log(recordedBlob)
        // console.log(screenCaptureZipFile)

        this.videodata = [];
        // this.screendata = []

        // lz.compress(recordedBlob.)
        // var reader = new FileReader();
        // reader.readAsDataURL(recordedBlob); 
        // reader.onloadend = function() {
        //     let points={}
        // var base64data = reader.result;                
        //     console.log(base64data);
        //     let i=0;
        //     while(i+2<base64data.length){
        //         if(points[base64data[i]+base64data[i+1]+base64data[i+2]]){
        //             points[base64data[i]+base64data[i+1]+base64data[i+2]]++;
        //         }
        //         else{
        //             points[base64data[i]+base64data[i+1]+base64data[i+2]]=1;
        //         }
        //         i++;
        //     }
        //     let sortable = [];
        //     for (var vehicle in points) {
        //         sortable.push([vehicle, points[vehicle]]);
        //     }

        //     sortable.sort(function(a, b) {
        //         return b[1] - a[1];
        //     });
        //     console.log(sortable)
        //     console.log(base64data.replaceAll(sortable[0][0],']'));


        // }


        fetch(`${URL}api/salvar_dados`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    if (resp.status == false || resp.message == 'Server Error') {
                        this.props.get_processo();
                    }
                    else if (resp.processo == null) {
                        this.props.changeState({ processo: null, stream: null, stream_screen: null });
                    }
                    else {
                        let data_request = {};
                        if (resp.request_user != null && resp.request_user.updated_at != this.props.request_user?.updated_at) {
                            data_request = {
                                request_user: resp.request_user
                            };
                            if (resp.request_user.approved == null) {
                                this.setState({ loading_pause: true })
                            }
                            else if (resp.request_user.approved == true && resp.request_user.pause_end == null) {
                                if(resp.request_user.started==null){
                                    this.setState({ show_pause_modal_accepted: true });
                                }
                                else{
                                    this.setState({ show_pause_timer: true },()=>{
                                        clearTimeout(this.timer_timeout_request)
                        this.last_date_request = moment().format('YYYY-MM-DD HH:mm:ss');

                                        this.incTimerRequest()
                                    });
                                }
                            }
                        }

                        if (resp.processo != null && (resp.processo.chat_type != this.props.processo.chat_type || resp.processo.updated_at != this.props.processo.updated_at || this.props.processo.fim != resp.processo.fim || data_request.request_user != undefined)) {
                            this.props.changeState({ processo: resp.processo, ...data_request });
                            this.fim = resp.processo.fim;
                        }

                        if (resp.url_video != undefined) {
                            axios.put(resp.url_video, recordedBlob, {
                                headers: {
                                    "Content-Type": recordedBlob.type
                                }
                            }).then((resp) => {
                                console.log(resp)
                            }).catch((e) => {
                                console.log(e);
                            });
                        }


                        // if (resp.url_screen != undefined && screenBlob != null) {
                        //     axios.put(resp.url_screen, screenBlob, {
                        //         headers: {
                        //             "Content-Type": screenBlob.type
                        //         }
                        //     }).then((resp) => {
                        //         console.log(resp)
                        //     }).catch((e) => {
                        //         console.log(e);
                        //     });
                        // }

                        const file = this.canvas.current.toDataURL('image/jpeg', 0.15);
                        // if(this.file==null){
                        //     this.file=file;
                        // }
                        // else{
                        //     if(this.file==file){
                        //         console.log('imagens iguais')
                        //         console.log(this.file)
                        //     }
                        // }
                        // this.file=file;

                        const type = file.split(';')[0].split('/')[1]
                        const buffer = Buffer.from(file.replace(/^data:image\/\w+;base64,/, ""), 'base64');
                        if (resp.url_image != undefined) {

                            console.log(buffer);
                            axios.put(resp.url_image, buffer, {
                                headers: {
                                    "Content-Type": type
                                }
                            }).then((resp) => {
                                console.log(resp)
                            }).catch((e) => {
                                console.log(e);
                            });
                        }



                    }
                }




            } catch (err) {
                console.log(err);


            }

        })
            .catch((err) => {

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    async finalizar_avaliacao() {

        // this.aceitar_regras(this.canvas.current.toDataURL('image/jpeg',0.15));
        let textos = [];
        let titulos = [];
        let redacao_ids = [];
        for (let i = 0; i < this.props.redacoes.length; i++) {
            textos.push(this.props.redacoes[i].resultado.texto);
            titulos.push(this.props.redacoes[i].resultado.titulo);
            redacao_ids.push(this.props.redacoes[i].redacao.id);
        }

        let respostas_questionarios = [];
        for (let i = 0; i < this.props.questionarios.length; i++) {
            respostas_questionarios.push({ respostas: this.props.questionarios[i].respostas, questionario_id: this.props.questionarios[i].questionario.id });
        }

        let data = {}
        if (Object.values(this.respostas).length > 0) {
            data = { respostas: JSON.stringify(this.respostas) }
        }

        fetch(`${URL}api/finalizar_avaliacao`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                titulo: titulos,
                redacao_id: redacao_ids,
                texto: textos,
                processo_id: this.props.processo.id,
                respostas_questionarios,
                ...data
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    if (this.props.socket != null) {
                        this.props.socket.emit("finish_event", { sala_id: this.props.sala.id, aluno_id: this.props.user.id, finish: true, faculdade_id: this.props.faculdade.id });
                    }
                    this.isFinish=true;
                    this.setState({ loading_finish: false });
                    this.props.aceitar_regras(resp);
                }
            } catch (err) {
                this.setState({ loading_finish: false });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ loading_finish: false });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    changeTab(k) {
        console.log(k)
        this.setState({ tab: k })
        this.salvar_log_warning('alter_tab', 'Aba: ' + k.toString())
    }


   

    finishExam() {
        if (this.props.processo.exam_per_time == false) {
            let achou = false;
            let achou_redacao = false;
            let achou_questionario = false;
            let achou_dissertativa = false;
            try {

                for (let i = 0; i < this.props.provas.length; i++) {
                    for (let j = 0; j < this.props.provas[i].questoes.length; j++) {
                        if (this.respostas_dissertativa[this.props.provas[i].prova.id] == undefined) {
                            this.respostas_dissertativa[this.props.provas[i].prova.id] = {}
                        }
                        let respostas = { ...this.props.provas[i].respostas, ...this.respostas_dissertativa[this.props.provas[i].prova.id] }

                        if (this.props.provas[i].respostas[this.props.provas[i].questoes[j].id] == undefined && this.props.provas[i].questoes[j].type_questao == 'simples') {
                            achou = true;
                            break;
                        }
                        else if (this.props.provas[i].questoes[j].type_questao == 'dissertativa' && ((respostas[this.props.provas[i].questoes[j].id] == undefined || respostas[this.props.provas[i].questoes[j].id].trim().length == 0)
                        )) {
                            achou_dissertativa = true;
                            break;
                        }
                        else if (this.props.provas[i].questoes[j].type_questao == 'conjunto') {
                            this.props.provas[i].questoes[j].questoes.map((item3, id3) => {
                                if (this.props.provas[i].respostas[item3.id] == undefined) {
                                    achou = true;
                                }
                            })
                        }
                    }
                    if (achou == true) {
                        break;
                    }
                }
                for (let k = 0; k < this.props.questionarios.length; k++) {

                    for (let i = 0; i < Object.keys(this.props.questionarios[k].respostas).length; i++) {
                        let resposta = this.props.questionarios[k].respostas[Object.keys(this.props.questionarios[k].respostas)[i]];
                        // console.log(resposta);
                        if (resposta['type'] == 'multipla_escolha' && resposta['alternativa_id'] == null) {
                            // this.setState({ loading_save: false, msg_erro: 'Você não respondeu todas as questões', show_error: true });
                            // return;
                            achou_questionario = true;
                        }
                        if (resposta['type'] == 'paragrafo') {
                            let texto = Object.values(resposta['textos'])[0]['texto'];
                            if ((texto == null || texto.trim().length == 0) && (Object.values(resposta['checkboxs']).length == 0 || Object.values(resposta['checkboxs'])[0] == false)) {
                                // this.setState({ loading_save: false, msg_erro: 'Você não respondeu todas as questões', show_error: true });
                                // return;
                                achou_questionario = true;
                            }
                        }
                        if (resposta['type'] == 'multipla_escolha') {
                            for (let j = 0; j < Object.values(resposta['textos']).length; j++) {
                                let texto = resposta['textos'][Object.keys(resposta['textos'])[j]];
                                if ((texto['texto'] == null || texto['texto'].trim().length == 0) && ((texto['required'] == 'required') || (isUuid(texto['required']) && texto['required'] == resposta['alternativa_id']))) {
                                    // this.setState({ loading_save: false, msg_erro: 'Você não respondeu todas as questões', show_error: true });
                                    // return;
                                    achou_questionario = true;
                                }
                            }
                        }
                    }
                }


                for (let i = 0; i < this.props.redacoes.length; i++) {
                    let redacao = this.props.redacoes[i];
                    if (redacao.resultado.texto == null || redacao.resultado.texto.trim().length == 0) {
                        achou_redacao = true;
                        break
                    }
                }
            } catch (e) {
                console.log(e)
            }

            console.log(achou_dissertativa)
            if (achou_dissertativa == true) {
                this.setState({ show_warning: true, title_warning: 'Você não respondeu toda a avaliação. Existe(m) caderno(s) de prova não respondido(s). Você tem certeza que deseja finalizar?' })
            }
            else if (achou == true) {
                this.setState({ show_warning: true, title_warning: 'Você não respondeu toda a avaliação. Existe(m) caderno(s) de prova não respondido(s). Você tem certeza que deseja finalizar?' })
            }

            else if (achou_redacao == true) {
                this.setState({ show_warning: true, title_warning: 'Você não respondeu toda a avaliação. Existe(m) caderno(s) de prova não respondido(s). Você tem certeza que deseja finalizar?' })

            }
            else if (achou_questionario == true) {
                this.setState({ show_warning: true, title_warning: 'Você não respondeu toda a avaliação. Existe(m) caderno(s) de prova não respondido(s). Você tem certeza que deseja finalizar?' })

            }
            else {
                this.setState({ show_warning: true, title_warning: 'Você tem certeza que quer finalizar a avaliação?' })
            }
        }
        else {
            console.log(this.selects_questions);
            let filtered = [...this.props.questionarios, ...this.props.provas, ...this.props.redacoes].map((item, id) => {
                let return_value = false;
                if (item.prova != undefined) {
                    let questoes_number = item.questoes.map((item, id) => {
                        if (item.type_questao == 'conjunto') {
                            return item.questoes.length;
                        }
                        else {
                            return 1;
                        }
                    }).reduce((a, b) => a + b, 0);

                    if (item.order != null && questoes_number < this.selects_questions[item.prova.id]) {
                        return_value = true
                    }


                }
                else {
                    if (item.resultado.finish == true) {
                        return_value = true;
                    }
                }

                return return_value;

            });
            let index_filter = filtered.indexOf(false);
            console.log(filtered)
            if (index_filter == -1) {
                this.setState({ show_warning: true, title_warning: 'Você tem certeza que quer finalizar a avaliação?' })
            }
            else {
                this.setState({ show_warning: true, title_warning: 'Existe(m) caderno(s) de prova não finalizado(s). Você tem certeza que deseja finalizar?' })
            }

        }
        this.salvar_log_warning('click_finish', 'Pop-up encerrar')
    }

    sendRequest(message) {
        if (this.props.connect == true) {
            this.setState({ loading_pause: true }, () => {
                this.waitResponsePause = setTimeout(() => {
                    this.setState({ loading_pause: false, show_warning_page: true, message_warning: 'Não foi possível concluir sua solicitação de pausa. Verifique se sua conexão está estável.' });
                }, 15000);
                this.props.socket.emit("request_pause", { reason: message, sala_id: this.props.sala.id, aluno_id: this.props.user.id, processo_id: this.props.processo.id });
            });
        }
        else {
            this.setState({ loading_pause: false, show_warning_page: true, message_warning: 'Não foi possível concluir sua solicitação de pausa. Verifique se sua conexão está estável.' });
        }
    }

    timerPause(){
       
        this.timer_pause_init-=1;
        if(this.timer_pause_init<=0){
            document.getElementById('timer_request_init').textContent='0 segundo(s)';
            this.timer_pause_init=20;
            this.setState({ loading_pause_response: true });
            this.pause_status();
        }
        else{
            document.getElementById('timer_request_init').textContent=this.timer_pause_init +' segundo(s)';
            this.timeout_pause_init = setTimeout(()=>{
                this.timerPause();
            },1000);
        }
    }



    render() {
        let index = 0;
        let ultrapassou_flag = Object.values(this.props.ultrapassou_linhas).includes(true) ? true : false;
        return (
            <div >
                <RequestUser show_pause_modal={this.state.show_pause_modal} sendRequest={(e) => this.sendRequest(e)} setModal={(e) => this.setState({ show_pause_modal: e })} />
                {this.state.show_pause_modal_accepted==true && <RequestAccepted 
                goToQuestion={(prova_id,questao_id,select_question)=>{
                    console.log('go to question',prova_id,questao_id,select_question)
                    let prova_index = this.props.provas.map((item,id)=>{
                        if(item.prova.id==prova_id) return id;
                        else return undefined
                    }).filter((item)=>item!=undefined)[0]
                    prova_index+=this.props.questionarios.length
                    this.setState({show_pause_modal_accepted:false, tab:prova_index,goTo:{
                        prova_id,questao_id,select_question
                    }})
                }}
                show_pause_modal_accepted={this.state.show_pause_modal_accepted} sendRequest={(e) => this.pause_status()} setModal={(e) => this.setState({ show_pause_modal_accepted: e })} />}
               
                <canvas ref={this.canvas} id="background-canvas-andamento" style={{ position: 'absolute', top: '0px', left: '0px', zIndex: '-1' }}></canvas>
                <video ref={this.videoRef} muted autoPlay id="background-canvas" style={{ height: '100px', position: 'absolute', top: 200, zIndex: -1 }}></video>
                {this.props.processo.simulado == false && (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && this.props.processo.chat_type != 'none' && <Chat send_message={(m) => this.props.send_message(m)} />}

                {this.state.show_pause_timer == false && <>

                    <p style={{ fontSize: 18, color: 'black', marginBottom: 0 }}><b>ATENÇÃO</b></p>
                    {this.props.processo.is_interview == false && <>
                        <p style={{ color: 'black', marginBottom: 0 }}>A avaliação contém os seguintes cadernos:</p>
                        {this.props.questionarios.map((item, id) => {
                            return <p key={id} style={{ color: 'black', marginBottom: 0 }}>- {item.questionario.name}</p>
                        })}
                        {this.props.provas.map((item, id) => {
                            return <p key={id} style={{ color: 'black', marginBottom: 0 }}>- {item.prova.name}</p>
                        })}
                        {this.props.redacoes.map((item, id) => {
                            return <p key={id} style={{ color: 'black', marginBottom: 0 }}>- {item.redacao.name}</p>
                        })}
                        {(this.props.questionarios.length + this.props.provas.length + this.props.redacoes.length > 1) && <>
                            <p style={{ color: 'black', marginBottom: 0 }}>Os cadernos estão separados por abas logo abaixo.
                                {this.props.processo.exam_per_time == true ? <> Ao finalizar um caderno, você poderá avançar para o próximo.</> : <><b>CLIQUE</b> nas abas para poder responder as {this.props.redacoes.length > 0 ? 'questões/redação' : 'questões'} daquele respectivo caderno.</>}
                            </p>
                        </>}

                        {this.props.questionarios.length > 0 && <p style={{ color: 'black', marginBottom: 0 }}>O questionário é salva automaticamente a cada 30 segundos ou ao finalizá-lo.</p>}

                        {this.props.provas.map((item) => item.prova.type).includes('multipla_escolha') && <p style={{ color: 'black', marginBottom: 0 }}>Questões de múltipla escolha são salvas automaticamente ao responder a questão.</p>}
                        {this.props.provas.map((item) => item.prova.type).includes('dissertativa') && <p style={{ color: 'black', marginBottom: 0 }}>Questões dissertativas/discursivas são salvas automaticamente a cada 30 segundos ou ao avançar para a próxima questão.</p>}
                        {this.props.redacoes.length > 0 && <p style={{ color: 'black', marginBottom: 0 }}>A redação é salva automaticamente a cada 30 segundos.</p>}


                        <p style={{ color: 'black' }}>Ao terminar de responder <b>TODOS</b> os cadernos, clique no botão "Encerrar e entregar a avaliação", que finalizará sua avaliação.</p>
                        {this.props.user.accessibility==true && this.props.user.type_supervisor!='none' && <p style={{ color: 'black',textAlign:"center",fontSize:17,textTransform:'uppercase' }}><b>Para chamar indicar onde o fiscal deve ler, grife o texto e clique no botão "Chamar Atenção" que se encontra na janela do fiscal.</b> </p>}

                        {this.props.connect == false && this.props.socket != null && <div>
                            <p style={{ fontSize: '1.2rem', color: 'red', textAlign: 'center',textTransform:'uppercase' }}>Sua conexão está instável...</p>
                        </div>}
                    </>}

                    {this.props.processo.is_interview == true && <>
                        <p style={{ color: 'black', marginBottom: 0 }}>Esta é uma avaliação oral onde o candidato entrará em chamada com os avaliadores</p>
                        <p style={{ color: 'black' }}>Para entrar em chamada, dentro do horário de sua avaliação, vá na aba abaixo e clique no botão "Entrar em chamada". </p>
                    </>}

                    {/* <FaceDetection /> */}
                    {/* <div className='timer_exam' id='timer' style={this.props.processo.type_fiscalization == 'none'?{bottom:0,right:0,top:'unset'}:{}}
                >

                </div> */}
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
                        {this.props.processo.need_pause == true && <div style={{ marginRight: 10, display: 'flex' }}>

                            {this.state.loading_pause == false && this.props.socket != null && <button onClick={() => {
                                if (this.props.request_user != null && this.props.request_user.approved == true && this.props.request_user.started == null) {
                                    this.setState({ show_pause_modal_accepted:true })
                                }
                                else {
                                    this.setState({ show_pause_modal: true });
                                }
                                // this.finishExam();
                            }} type="button" id="pausa"  className="btn btn-info btn-lg waves-effect waves-light"
                            >{(this.props.request_user == null || this.props.request_user.approved == false || this.props.request_user.pause_end != null) ? 'Pedir Pausa' : 'Iniciar Pausa'}<i className='mdi mdi-pause-circle'></i></button>}
                            {this.state.loading_pause == true && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <span style={{ color: 'darkgoldenrod' }}>Sua solicitação de pausa está em andamento</span>
                                <div className="spinner-border text-primary" style={{ marginRight: 0, marginTop: 0, marginLeft: 10 }} role="status">
                                    <span className="sr-only">Loading...</span>
                                </div></div>}
                        </div>}

                        {this.state.loading_finish == false && <button onClick={() => {
                            this.finishExam();
                        }} type="button" id="finalizar" disabled={ultrapassou_flag} className="btn btn-outline-danger btn-lg waves-effect waves-light"
                            style={{
                                background: ultrapassou_flag == true ? 'grey' : '',
                                border: ultrapassou_flag == true ? '1px solid grey' : ''
                            }}>Encerrar e entregar a avaliação  <i className='mdi mdi-pencil'></i></button>}
                        {this.state.loading_finish == true && <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>}
                    </div>
                    <p style={{ color: 'black', marginBottom: 0, fontWeight: 'bold' }}>{this.props.processo.is_interview == false ? 'CADERNOS:' : 'AVALIAÇÃO ORAL:'}</p>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={this.state.tab}
                        onSelect={(k) => {
                            console.log(k)
                            if (this.props.processo.exam_per_time == false) {
                                this.changeTab(k);
                            }
                        }}
                    >
                        {this.props.questionarios.map((item, id) => {
                            index += 1;

                            return (
                                <Tab tabClassName={'tab'} key={id} eventKey={index - 1} title={item.questionario.name}>
                                    {/* <p>oi</p> */}
                                    {this.props.screenExtended == false && <Questionario
                                        finishExam={() => this.finishExam()}
                                        changeTab={(k) => {
                                            this.changeTab(k);
                                        }}

                                        get_processo={() => this.props.get_processo()} indice={id} index={index - 1} />}

                                </Tab>
                            );
                        })}
                        {this.props.provas.map((item, id) => {
                            index += 1;
                            return (
                                <Tab tabClassName={'tab'} key={id} eventKey={index - 1} title={item.prova.name}>
                                    {this.props.screenExtended == false && <Prova
                                        goTo={this.state.goTo}
                                        setPosition={(ind) => {
                                            this.selects_questions[item.prova.id] = ind;
                                        }}
                                        finishExam={() => this.finishExam()}

                                        changeTab={(k) => {
                                            this.changeTab(k);
                                        }}
                                        changeResposta={(prova_id, id, value) => {
                                            if (!this.respostas_dissertativa[prova_id]) {
                                                this.respostas_dissertativa[prova_id] = {};
                                            }
                                            this.respostas_dissertativa[prova_id][id] = value;
                                            this.respostas[prova_id] = { questao_id: id, value, prova_id };
                                        }} get_processo={() => this.props.get_processo()} indice={id} index={index - 1} />}
                                </Tab>
                            );

                        })}
                        {this.props.redacoes.map((item, id) => {
                            index += 1;

                            return (
                                <Tab tabClassName={'tab'} key={id} eventKey={index - 1}
                                    title={item.redacao.name}>
                                    {/* <p>oi</p> */}
                                    {this.props.screenExtended == false && <Redacao
                                        finishExam={() => this.finishExam()}

                                        changeTab={(k) => {
                                            this.changeTab(k);
                                        }}
                                        indice={id} index={index - 1} />}

                                </Tab>
                            );
                        })}

                        {this.props.entrevistas.map((item, id) => {
                            index += 1;

                            return (
                                <Tab tabClassName={'tab'} key={id} eventKey={index - 1}
                                    title={item.entrevista.name}>
                                    {/* <p>oi</p> */}
                                    {this.props.screenExtended == false && <div>
                                        <div style={{ marginTop: '1rem' }}>
                                        <h5 className="mt-0 header-title" style={{ fontSize: '1.5rem', textAlign: 'center' }}>
                                                    {item.entrevista.name}</h5>
                                        <div style={{whiteSpace:'pre-line'}}><b style={{color:'black',fontSize:18}}>Descrição:</b> <div dangerouslySetInnerHTML={{__html:item.entrevista.descricao}}></div></div>
                                        <div style={{marginTop:10}}>
                                            {item.entrevista.topicos.map((item2,id2)=>(
                                                <div key={id2} style={{display:'flex',alignItems:'center',marginBottom:10}}><div style={{background:'black',borderRadius:100,minWidth:6,minHeight:6,marginRight:5}}></div> <span>{item2.text}</span></div>
                                            ))}
                                        </div>
                                            <p style={{color:'black',textAlign:'center',marginBottom:10}}><b >Início:</b> {item.exam_oral?.datetime_initial? moment(item.exam_oral?.datetime_initial).format('DD/MM/YYYY HH:mm'):'-'}</p>
                                            <p style={{color:'black',textAlign:'center',marginBottom:10}}><b >Fim:</b> {item.exam_oral?.datetime_end ?moment(item.exam_oral?.datetime_end).format('DD/MM/YYYY HH:mm'):'-'}</p>

                    
                                            {<div style={{ justifyContent:'center',alignItems:'center', display: 'flex' }}>

                                                {this.props.socket != null && <button onClick={() => {
                                                    this.props.changeState({ show_call: true })
                                                    // this.finishExam();
                                                }} type="button" id="finalizar" className="btn btn-outline-info btn-lg waves-effect waves-light"
                                                >Entrar em chamada<i className='mdi mdi-call-made'></i></button>}

                                            </div>}
                                            <hr/>
                                        </div>
                                    </div>}

                                </Tab>
                            );
                        })}


                    </Tabs>



                    
                    <div className='row'>
                        <div className='col-12'>
                            <p className='back_top' style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => {
                                document.getElementById('content-page').scrollIntoView({ behavior: "smooth" })
                            }}><i className="mdi mdi-arrow-up" /> Voltar ao topo</p>
                        </div>

                    </div>
                    <SweetAlert
                        warning
                        title={this.state.title_warning}
                        onConfirm={this.onConfirm.bind(this)}
                        onCancel={this.onCancel.bind(this)}
                        show={this.state.show_warning}
                        confirmBtnText='Sim, desejo finalizar'
                        cancelBtnText='Cancelar'
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        showCancel={true}
                    >
                        Ao finalizar você não poderá mais realizar alterações
                    </SweetAlert>
                    <SweetAlert
                        warning
                        title={'Aviso!'}
                        onConfirm={() => this.setState({ show_warning_page: false })}
                        show={this.state.show_warning_page}
                        confirmBtnText='Entendi'
                        confirmBtnBsStyle="danger"
                    >
                        {this.state.message_warning}
                    </SweetAlert>
                </>}
                {this.state.show_pause_timer == true &&
                    <div>
                        <hr />
                        {<>
                            <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>Sua solicitação de pausa foi aceita!</h4>
                            <p style={{ color: 'black', marginBottom: 5, fontSize: 17, textAlign: 'center' }}>Não feche a aba de prova e não oculte a câmera durante a pausa. Caso isso ocorra será registrado como saída de prova. </p>
                            <p style={{ color: 'black', marginBottom: 5, fontSize: 17, textAlign: 'center' }}>Retorne antes do tempo finalizar, caso contrário, será avisado ao fiscal que a pausa finalizou e o candidato não retornou.</p>
                            {this.props.request_user.started != null  && <p style={{ color: 'black', marginBottom: 5, fontSize: 16, textAlign: 'center' }}>Para voltar para a prova, clique no botão “Encerrar pausa”.</p>}

                            <p style={{ textAlign: 'center',fontSize:17 }} id="timer_request"><b>Tempo concedido:</b> {this.convertMinute(this.props.request_user.time)}</p>
                            {this.props.request_user.started == null  && <p style={{ textAlign: 'center',fontSize:17 }} >Sua pausa irá iniciar em: <span id="timer_request_init"></span></p>}
                            
                            <div className='d-flex mt-1 justify-content-center'>
                                {this.state.loading_pause_response != true ? (this.props.request_user.started == null ? <>
                                    {/* <button onClick={() => {
                                        this.setState({ show_pause_timer: false, loading_pause: false })
                                    }} className="btn btn-secondary btn-outline btn-circle m-r-5 w-50" ><i
                                        className="mdi mdi-file"
                                        style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                            Voltar para a prova</i></button> */}
                                    {/* <button onClick={() => {
                                        this.setState({ loading_pause_response: true });
                                        this.pause_status();
                                    }} className="btn btn-success btn-outline btn-circle w-50" ><i
                                        className="mdi mdi-pause-circle"
                                        style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                            Iniciar pausa</i></button> */}
                                </> : (<button onClick={() => {
                                    this.setState({ loading_pause_response: true });
                                    this.pause_status('end');
                                }} className="btn btn-info btn-outline btn-circle w-100 mt-2" ><i
                                    className="mdi mdi-play-circle"
                                    style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                        Encerrar pausa</i></button>)) : <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>}


                            </div>

                        </>}

                    </div>
                }



            </div >

        );
    }

    convertMinute(value) {
        let label = '';
        if (value < 60) {
            label = '30 segundos';
        }
        else {
            label = (parseInt(value / 60) + ' minuto(s)') + (Number.isInteger(value / 60) ? '' : ' e 30 segundos');
        }
        return label;
    }

    onConfirm() {
        this.setState({ show_warning: false, loading_finish: true });
        this.finalizar_avaliacao();
        // this.aceitar_regras(this.canvas.current.toDataURL('image/jpeg', 0.15));
    }

    onCancel() {
        this.setState({ show_warning: false });
    }




}
const mapsStateToProps = (state) => (
    {
        tempo_atual: state.AppReducer.tempo_atual,
        processo: state.AppReducer.processo,
        faculdade: state.AppReducer.faculdade,
        stream: state.AppReducer.stream,
        stream_screen: state.AppReducer.stream_screen,

        token: state.AppReducer.token,
        provas: state.AppReducer.provas,
        redacoes: state.AppReducer.redacoes,
        questionarios: state.AppReducer.questionarios,
        mensagens: state.AppReducer.mensagens,

        socket: state.AppReducer.socket,
        user: state.AppReducer.user,
        sala: state.AppReducer.sala,
        ultrapassou_linhas: state.AppReducer.ultrapassou_linhas,
        screenExtended: state.AppReducer.screenExtended,
        request_user: state.AppReducer.request_user,
        connect: state.AppReducer.connect,
        show_call: state.AppReducer.show_call,
        entrevistas: state.AppReducer.entrevistas,

    }
);

export default withRouter(connect(mapsStateToProps, { logout, mudarProcesso, changeState, mudarMensagens })(AvaliacaoAndamento));


