
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect } from 'react-router-dom';
import { changeState, logout, mudarMensagens } from '../actions/AppActions';
import { connect } from 'react-redux';

// users
import user4 from '../assets/images/users/user-4.jpg';
import moment from 'moment-timezone';
import NaoIniciada from './NaoIniciada';
import { DEBUG, URL } from '../variables';
import AceiteRegras from './AceiteRegras';
import AvaliacaoAndamento from './AvaliacaoAndamento';
import Finalizada from './Finalizada';
import MeetingRoom from './Meet';
//

import { createGlobalStyle } from 'styled-components';
import { ProgressBar } from 'react-bootstrap';
import { CircularProgress } from '@material-ui/core';
import Chat from './Chat';
import SweetAlert from 'react-bootstrap-sweetalert';
let silence = () => {
    let ctx = new AudioContext(), oscillator = ctx.createOscillator();
    let dst = oscillator.connect(ctx.createMediaStreamDestination());
    oscillator.start();
    return Object.assign(dst.stream.getAudioTracks()[0], { enabled: false });
  }
const StyleQuestion = createGlobalStyle`
 *::selection {
    background-color: #f1c40f !important;
    color:black !important;
    font-weight:bold !important;
 }
`

class ProcessoUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',
            nao_iniciado: false,
            regras_processo: false,
            andamento: false,
            finalizada: false,
            loading_screen: true,
            finish: false,
            full_screen: false,
            need_face_validation:false,
            chat_show:false,
            message_access:'',
            loading_access:false,
            request_validation:{},
            show_warning_decline:false,
            show_warning_approved:false,
            show_btn_validation_timer:false,
            show_btn_validation:false
        };
        this.last_date = moment().format('YYYY-MM-DD HH:mm:ss');
        this.need_delete_validation=[]
    }


    exitHandler(e) {
        // console.log(e)
        if (document.webkitIsFullScreen === false) {
            ///fire your event
            this.setState({ full_screen: false });
            this.event_fullscreen('exit_fullscreen');
        }
        else if (document.mozFullScreen === false) {
            ///fire your event
            this.setState({ full_screen: false });
            this.event_fullscreen('exit_fullscreen');
        }
        else if (document.msFullscreenElement === false) {
            ///fire your event
            this.setState({ full_screen: false });
            this.event_fullscreen('exit_fullscreen');
        }
        else if (document.fullscreenElement) {
            this.setState({ full_screen: true });

        }
    }

    openFullscreen(elem) {
        if (elem.requestFullscreen) {
            elem.requestFullscreen().then(() => {
                this.setState({ full_screen: true });
            });

        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen().then(() => {
                this.setState({ full_screen: true });
            });

        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen().then(() => {
                this.setState({ full_screen: true });
            });

        }
        else {
            this.setState({ full_screen: true });
        }
        this.clicked = false;
    }

    solicitar_camera(rules) {
        // this.setState({ loading_camera: true, msg: '', show_solicitar: false });
        navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(() => this.get_camera(rules)).catch(this.handleError.bind(this));
    }

    async get_camera(rules) {

        var devices = await navigator.mediaDevices.enumerateDevices();
        let device_local = localStorage.getItem("deviceId")
        console.log(devices);
        let exists = false;
        let devices_camera = [];
        let devices_mic = [];
        var _stream = null;
        let devices_cam_id = []
        let devices_mic_id = []

        for (let i = 0; i < devices.length; i++) {
            if (devices[i].kind == 'videoinput') {
                if (!devices_cam_id.includes(devices[i].deviceId)) {
                    devices_camera.push(devices[i]);
                    devices_cam_id.push(devices[i].deviceId);

                }
                if (device_local == devices[i].deviceId) {
                    exists = true;
                }
            }
            else if (devices[i].kind == 'audioinput') {
                if (!devices_mic_id.includes(devices[i].deviceId)) {
                    devices_mic.push(devices[i]);
                    devices_mic_id.push(devices[i].deviceId);

                }
            }

        }
        console.log(devices_camera);
        console.log('oi');
        if (devices_camera.length > 0 && devices_mic.length > 0) {
            let use = '';
            if (exists == true) {
                use = device_local;
            }
            else {
                localStorage.setItem("deviceId", devices_camera[0]);
                use = devices_camera[0];
            }
            console.log(use)
            this.props.changeState({ devices_camera: devices_camera, devices_mic, device_select: use })
            navigator.mediaDevices.getUserMedia({
                video: {
                    deviceId: use, frameRate: 4,
                    facingMode: "user",
                    width: 320,
                    height: 240
                }, audio: true
            }).
                then((str) => this.handleSuccess(str, rules)).catch(this.handleError.bind(this));
        }
        else {
            this.setState({ show_solicitar: true, loading_camera: false, msg: 'Não foi reconhecido nenhuma câmera ou microfone conectado em seu computador. Conecte e tente novamente' })
        }
    }

    request_screen_sharing(stream, rules) {
        let extended = DEBUG?false: window?.screen?.isExtended;
        if (extended == true) {
            if(stream){
                stream.getTracks().map((track)=>track.stop());
            }
            this.props.history.push({
                pathname: '/',
                search: '?error=Para compartilhar a tela você deve estar com apenas um monitor'
            });
        }
        else {
            navigator.mediaDevices
                .getDisplayMedia({
                    audio: false, video: {
                        frameRate: 24,
                        displaySurface: "monitor",
                        height: 500
                    }
                }
                )
                .then((strm) => {
                    if(this.unmount==true){
                        strm.getTracks().map((track) => track.stop());
                        return;
                    }
                    let displaySurface = strm.getVideoTracks()[0].getSettings().displaySurface;
                    if (displaySurface !== 'monitor') {
                        strm.getTracks().map((track) => track.stop());
                        // this.setState({ loading_camera: false, msg: 'Compartilhe a tela cheia', show_screen_sharing: true });
                        this.request_screen_sharing(stream);
                        this.salvar_log_permission('Compartilhou a opção errada de tela: ' + displaySurface);

                    }
                    else {
                        let track = silence();
                        strm.addTrack(track)
                        this.props.changeState({ stream, stream_screen: strm });
                        this.salvar_log_permission('Aceitou permissão de compartilhamento de tela');

                        if (rules == true) {
                            this.aceitar_regras(this.resp);
                            this.get_time_server();
                        }

                    }
                })
                .catch((err) => {
                    if(stream){
                        stream.getTracks().map((track)=>track.stop());
                    }
                    try {
                        this.salvar_log_permission('Recusou permissão de compartilhamento de tela: ' + err);
                    }
                    catch (e) {

                    }
                    this.props.history.push({
                        pathname: '/',
                        search: '?error=Compartilhamento Tela foi cancelado pelo candidato. Compartilhe novamente para realizar a avaliação'
                    });
                    // this.setState({ loading_camera: false, msg: 'Compartilhe a tela cheia para avançar', show_screen_sharing: true });
                    console.error(err)
                });
        }

    }

    handleSuccess(stream, rules) {
        stream.oninactive = ()=>{
            try {
                this.salvar_log_permission('Cancelou permissão de câmera');
            }
            catch (e) {

            }
            this.props.history.push({
                pathname: '/',
                search: this.props.processo.screen_sharing == true ? '?error=Compartilhamento de Vídeo/Áudio ou Tela foi cancelado pelo candidato. Compartilhe novamente para realizar a avaliação' : '?error=Compartilhamento de Vídeo/Áudio foi cancelado pelo candidato. Compartilhe novamente para realizar a avaliação'
            });
        }
        // this.get_processo();
        this.salvar_log_permission('Aceitou permissão de câmera');

        if (this.props.processo.screen_sharing == true && this.props.processo.type_fiscalization == 'all') {
            this.request_screen_sharing(stream, rules);
        }
        else {
            this.props.changeState({ stream });
            this.aceitar_regras(this.resp);
            this.get_time_server();
        }

        // screenshotButton.disabled = false;
        // window.location="/processos_user"

        // this.setState({ show_aceitar: true, loading_camera: false, show_video: true,stream:stream });
        // this.videoRef.current.style.display = 'flex';

        // this.videoRef.current.srcObject = stream;
        // this.videoRef.current.volume = 0;
    }

    handleError(stream) {
        console.log(stream);
        let msg = '';
        if (stream.toString().includes('Permission denied')) {
            msg = 'Você bloqueou seu acesso a camera. Para liberá-lo vá nas configurações de seu navegador.';
        }
        else if (stream.toString().includes('Permission dismissed')) {
            msg = 'Aceita as permissões de acesso a câmera para poder avançar.';
        }
        else if (stream.toString().includes('Could not start video source')) {
            msg = 'Não foi possível acessar sua câmera. Conecte uma câmera, feche todas suas outras aplicações e tente novamente.';
        }
        else if (stream.toString().includes('Could not start audio source')) {
            msg = 'Não foi possível acessar seu microfone. Conecte um microfone, feche todas suas outras aplicações e tente novamente.';
        }
        else if (stream.toString().includes('NotFoundError')) {
            msg = 'Não foram reconhecidos nenhuma câmera ou microfone conectados em seu computador. Conecte-os e tente novamente.';
        }
        else {
            msg = stream.toString();
        }
        this.salvar_log_permission(msg+' '+stream.toString());
        this.setState({ redirect: true });

    }

    componentDidMount() {
        this.clicked = false;
        this.exitHandlerEvent = this.exitHandler.bind(this);
        document.addEventListener('fullscreenchange', this.exitHandlerEvent, false);
        document.addEventListener('mozfullscreenchange', this.exitHandlerEvent, false);
        document.addEventListener('MSFullscreenChange', this.exitHandlerEvent, false);
        document.addEventListener('webkitfullscreenchange', this.exitHandlerEvent, false);
        this.unmount=false;
        this.listen_channels();
        this.get_processo();
        if(this.props.processo.face_validation==true){
            this.salvar_log_permission('Precisa de validação facial','need_face_validation');
            
            if(this.props.processo.face_validation_periodicaly==true){
            this.timeout_validation = setInterval(()=>{
                let time=this.props.processo.time_validation_periodicaly;
                let data = this.props.getLastDetection();
                console.log('teste de validação')
                console.log(this.state.request_validation)
                console.log(data.last_audio_detected!=null,
                    data.last_face_recognize!=null,
                    this.state.andamento==true,
                    this.props.processo.face_validation==true,
                    this.state.need_face_validation==false,
                    (this.state.request_validation?.approved!=true || this.state.request_validation?.fiscal_id==null),
                    !(this.props.request_user?.started!=null && this.props.request_user?.pause_end==null),
                    (Math.abs(moment().diff(data.last_audio_detected,'seconds'))>time 
                    || Math.abs(moment().diff(data.last_face_recognize,'seconds'))>time ),
                    Math.abs(moment().diff(data.last_audio_detected,'seconds')),
                    Math.abs(moment().diff(data.last_face_recognize,'seconds'))
                )


                if( 
                    data.last_audio_detected!=null &&
                    data.last_face_recognize!=null &&
                    this.state.andamento==true && 
                    this.props.processo.face_validation==true &&
                    this.props.processo.face_validation_periodicaly==true &&
                    this.state.need_face_validation==false &&
                    (this.state.request_validation?.approved!=true || this.state.request_validation?.fiscal_id==null) &&
                    !(this.props.request_user?.started!=null && this.props.request_user?.pause_end==null) &&
                    (Math.abs(moment().diff(data.last_audio_detected,'seconds'))>time 
                    || Math.abs(moment().diff(data.last_face_recognize,'seconds'))>time )
                ){
                    console.log('precisa de validação')
                    this.props.changeStateParent({audio_detected:false,face_detected:false});
                    this.setState({need_face_validation:true,loading_access:false});
                    this.validation=false;
                    this.salvar_log_permission('Precisa de validação facial','need_face_validation_again');
                }
            },10000)
        }
        }

        // if (this.props.stream == null && this.props.processo_resultado!=null && this.props.processo_resultado.status=='I') {
        //     this.setState({ loading_screen: true });

        //     this.solicitar_camera();
        // }
        // else {
        //     this.get_processo();
        // }
    }

    salvar_log_permission(msg,type='permission') {
        fetch(`${URL}api/salvar_log?screen=${type}&processo_id=${this.props.processo.id}&processo_name=${this.props.processo.name}&msg=${msg}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {

                }
            } catch (err) {
                console.log(err);

            }

        })
            .catch((err) => {
                console.log(err);

            });
    }

    componentDidUpdate(props,state) {
        console.log(props.processo, this.props.processo);

        if (props.processo.updated_at != this.props.processo.updated_at && props.processo.screen_sharing == false && this.props.processo.screen_sharing == true && this.props.processo_resultado.status == 'I' && this.props.processo.type_fiscalization == 'all') {
            console.log(props.processo);
            console.log('entrou');
            // this.solicitar_camera(false);
            this.request_screen_sharing(this.props.stream, false);

        }

        if(this.props.socket!=props.socket){
            this.listen_channels();
        }

        if(this.state.need_face_validation!=state.need_face_validation && this.state.need_face_validation==true && 
            this.props.processo.face_validation==true){
                console.log('chamou timer')
                clearTimeout(this.timeout_btn_validation);
            this.timeout_btn_validation = setTimeout(()=>this.show_btn_routine(),25000);
        }
    }
//
    componentWillUnmount() {
        this.unmount=true;
        document.removeEventListener('fullscreenchange', this.exitHandlerEvent, false);
        document.removeEventListener('mozfullscreenchange', this.exitHandlerEvent, false);
        document.removeEventListener('MSFullscreenChange', this.exitHandlerEvent, false);
        document.removeEventListener('webkitfullscreenchange', this.exitHandlerEvent, false);
        clearTimeout(this.timeout_get_processo)
        clearTimeout(this.timeout_timeserver)
        clearTimeout(this.timer_timeout)
        clearTimeout(this.request_access_timeout)
        clearTimeout(this.timeout_btn_validation)
        clearInterval(this.timeout_request_delete)
        clearInterval(this.timeout_validation)
    }

    listen_channels(){
        if(this.listen!=true && this.props.socket!=null){
            this.listen=true
            this.props.socket.off('request_validation');
            this.props.socket.on('request_validation',(resp)=>{
                console.log('request_validation',resp)
                console.log(this.state.request_validation?.updated_at)
                if(resp.type=='response_delete'){
                    clearInterval(this.timeout_request_delete);
                }
                else if(this.validation!=true){
                    if(this.state.request_validation?.updated_at==undefined ||  
                        this.state.request_validation.updated_at<resp.request_validation.updated_at
                    ){
                        if(resp.request_validation.approved==true){
                            this.need_delete_validation=this.need_delete_validation.filter((item)=>item!=resp.request_validation?.id)
                            this.setState({show_warning_decline:false,show_warning_approved:true,request_validation:resp.request_validation});
                        }
                        else if(resp.request_validation.approved==false){
                            this.need_delete_validation=this.need_delete_validation.filter((item)=>item!=resp.request_validation?.id)
                            this.setState({show_warning_decline:true,show_warning_approved:false,request_validation:resp.request_validation,loading_access:false,message:''});
                        }
                    }
                }
            })

        }

    }

    show_btn_routine(){
        if(this.validation!=true && this.props.processo.simulado==false){
            // if(this.props.loading_media==false){
            this.setState({show_btn_validation:true});
            // }
            // else{
            //     this.timeout_btn_validation = setTimeout(()=>this.show_btn_routine(),20000);
            // }
        }
    }

    get_processo(flag = '') {
        if(this.props.processo!=null && this.props.processo.is_interview && this.props.socket!=null){
            this.props.socket.emit('exit_call', { aluno_id: this.props.user.id, processo_id: this.props.processo.id, id: this.props.user.id })
        }

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_processo${flag}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == 'Server Error') {
                    this.timeout_get_processo = setTimeout(() => {
                        this.get_processo();
                    }, 5000);
                    return;
                }
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null || resp.message == 'Server Error') {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                    this.timeout_get_processo = setTimeout(() => {
                        this.get_processo();
                    }, 5000);
                }
                else {
                    this.resp = resp;
                    if (resp.processo != null && (resp.processo.type_fiscalization == 'all' || resp.processo.type_fiscalization == 'image') && this.props.stream == null && resp.processo_resultado != null && resp.processo_resultado.status == 'I' ) {
                        // this.setState({ loading_screen: true });

                        this.solicitar_camera(true);
                    }
                    else {
                        this.aceitar_regras(resp);
                    }
                    

                }
            } catch (err) {
                // this.setState({ redirect: true });
                console.log(err);
                this.timeout_get_processo = setTimeout(() => {
                    this.get_processo();
                }, 5000);
            }

        })
            .catch((err) => {
                // this.setState({ redirect: true });

                console.log(err);
                this.timeout_get_processo = setTimeout(() => {
                    this.get_processo();
                }, 5000);
            });
    }
    goToRules(flag = '') {
        // this.get_time_server();
        this.get_processo(flag);

    }

    aceitar_regras(resp) {
        if (resp.not_processo == true) {
            this.props.changeState({ processo: null });
            return;
        }
        if (resp.not_in_time == true) {
            if (this.props.stream_screen != null) {
                try {
                    this.props.stream_screen.getVideoTracks()[0].onended = function () { }

                    this.props.stream_screen.getTracks().map((track) => track.stop())
                } catch (e) {

                }
            }
            if (this.props.stream != null) {
                try {
                console.log('finalizado stream6')

                    this.props.stream.oninactive = function () { }
                    this.props.stream.getTracks().map((track) => track.stop())
                } catch (e) {

                }
            }
            this.props.changeState({ stream_screen: null, stream: null });
            this.props.history.push({
                pathname: '/',
                search: '?error=' + resp.message
            });
            return;
        }
        // this.props.mudarUser(resp.user);
        // this.props.mudarProcesso(resp.processo);
        // this.props.mudarSala(resp.sala);
        // this.props.mudarTempoAtual(resp.tempo_atual);
        // if (this.props.stream == null && resp.finish != true ) {
        //     this.solicitar_camera();
        //     return;
        // }
        let processo_resultado = {};
        if (resp.processo_resultado != null) {
            processo_resultado.processo_resultado = resp.processo_resultado;
        }
        if(resp.request_validation!=null){
            if(!this.need_delete_validation.includes(resp.request_validation?.id)){
                this.need_delete_validation.push(resp.request_validation?.id)
            }
        }

        if (resp.nao_iniciado == true || resp.simulado == true) {
            if (resp.processo == null) {
                this.props.changeState({ user: resp.user, processo: null, ...processo_resultado });
            }
            else if (resp.processo.fim != this.props.processo.fim || resp.processo.updated_at != this.props.processo.updated_at) {
                this.props.changeState({ mensagens: resp.mensagens, user: resp.user, processo: resp.processo, sala: resp.sala, tempo_atual: resp.tempo_atual, ...processo_resultado });
            }
            else {
                this.props.changeState({ mensagens: resp.mensagens, user: resp.user, sala: resp.sala, tempo_atual: resp.tempo_atual, ...processo_resultado });
            }
            this.setState({request_validation:resp.request_validation!=null?resp.request_validation:this.state.request_validation,need_face_validation:(this.validation==true || this.state.request_validation?.approved==true || (this.props.left_movement==true && this.props.center_movement && this.props.right_movement && this.props.audio_detected))?false:true, loading_screen: false, finish: false, nao_iniciado: true, regras_processo: false, andamento: false, finalizada: false });
            if(!(this.props.left_movement==true && this.props.center_movement && this.props.right_movement && this.props.audio_detected) && this.validation!=true){
                this.props.changeStateParent({show_btn_change:true})
            }
        }
        else if (resp.regras_processo == true) {
            if (resp.processo == null) {
                this.props.changeState({ user: resp.user, processo: null, ...processo_resultado });
            }
            else {
                if (resp.processo.simulado == true && this.props.socket != null) {
                    this.props.socket.emit("line_check", { aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id, enter_now: true });
                }
                // else if (resp.processo.fim != this.props.processo.fim || resp.processo.updated_at != this.props.processo.updated_at) {
                this.props.changeState({ mensagens: resp.mensagens, user: resp.user, processo: resp.processo, sala: resp.sala, tempo_atual: resp.tempo_atual, ...processo_resultado });
                // }
            }
            // else {
            //     this.props.changeState({mensagens: resp.mensagens, user: resp.user, sala: resp.sala, tempo_atual: resp.tempo_atual, ...processo_resultado });
            // }
            this.setState({request_validation:resp.request_validation!=null?resp.request_validation:this.state.request_validation,need_face_validation:(this.validation==true || this.state.request_validation?.approved==true || (this.props.left_movement==true && this.props.center_movement && this.props.right_movement && this.props.audio_detected))?false:true, loading_screen: false, finish: false, nao_iniciado: false, regras_processo: true, andamento: false, finalizada: false });
            if(!(this.props.left_movement==true && this.props.center_movement && this.props.right_movement && this.props.audio_detected) && this.validation!=true){
                this.props.changeStateParent({show_btn_change:true})
            }
        }
        else if (resp.andamento == true) {
            for (let i = 0; i < resp.redacoes.length; i++) {
                if (resp.redacoes[i].resultado.texto == '' || resp.redacoes[i].resultado.texto == null) {
                    resp.redacoes[i].resultado.texto = '';
                }
                if (resp.redacoes[i].resultado.titulo == null) {
                    resp.redacoes[i].resultado.titulo = '';
                }
                resp.redacoes[i].resultado.texto = "\t" + resp.redacoes[i].resultado.texto;
            }
            if (resp.processo == null) {
                this.props.changeState({ user: resp.user, processo: null, ...processo_resultado });
            }
            else {
                if (resp.processo.simulado == true && this.props.socket != null) {
                    this.props.socket.emit("line_check", { aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id, enter_now: true });
                }
                this.props.changeState({request_user:resp.request_user,entrevistas:resp.entrevistas,  mensagens: resp.mensagens, redacoes: resp.redacoes, questionarios: resp.questionarios, provas: resp.provas, categorias: resp.categorias, user: resp.user, processo: resp.processo, sala: resp.sala, tempo_atual: resp.tempo_atual, ...processo_resultado });
            }

            // else if (resp.processo.fim != this.props.processo.fim || resp.processo.updated_at != this.props.processo.updated_at) {
            //     this.props.changeState({ mensagens: resp.mensagens, redacoes: resp.redacoes,questionarios: resp.questionarios, provas: resp.provas, categorias: resp.categorias, user: resp.user, processo: resp.processo, sala: resp.sala, tempo_atual: resp.tempo_atual, ...processo_resultado });
            // }
            // else {
            //     this.props.changeState({ mensagens: resp.mensagens, redacoes: resp.redacoes,questionarios: resp.questionarios, provas: resp.provas, categorias: resp.categorias, user: resp.user, sala: resp.sala, tempo_atual: resp.tempo_atual, ...processo_resultado });
            // }
            this.setState({request_validation:resp.request_validation!=null?resp.request_validation:this.state.request_validation,need_face_validation:(this.validation==true || this.state.request_validation?.approved==true || (this.props.left_movement==true && this.props.center_movement && this.props.right_movement && this.props.audio_detected))?false:true, loading_screen: false, finish: false, nao_iniciado: false, regras_processo: false, andamento: true, finalizada: false });
            if(!(this.props.left_movement==true && this.props.center_movement && this.props.right_movement && this.props.audio_detected) && this.validation!=true){
                this.props.changeStateParent({show_btn_change:true})
            }
            // this.props.mudarRedacoes(resp.redacoes);
            // this.props.mudarProvas(resp.provas);
            // this.props.mudarCategorias(resp.categorias);
            // this.props.mudarMensagens(resp.mensagens);

        }
        else if (resp.finish == true) {
            if (resp.processo == null) {
                this.props.changeState({ user: resp.user, processo: null, ...processo_resultado, provas: resp.provas != null ? resp.provas : [] });
            }
            else {
                this.props.changeState({ user: resp.user, processo: resp.processo, sala: resp.sala, tempo_atual: resp.tempo_atual, ...processo_resultado, provas: resp.provas != null ? resp.provas : [],redacoes:resp.redacoes });
            }
            // else if (resp.processo.fim != this.props.processo.fim || resp.processo.updated_at != this.props.processo.updated_at) {
            //     this.props.changeState({ user: resp.user, processo: resp.processo, sala: resp.sala, tempo_atual: resp.tempo_atual, ...processo_resultado, provas: resp.provas != null ? resp.provas : [] });
            // }
            // else {
            //     this.props.changeState({ user: resp.user, sala: resp.sala, tempo_atual: resp.tempo_atual, ...processo_resultado, provas: resp.provas != null ? resp.provas : [] });
            // }
            this.setState({ loading_screen: false, finish: true, nao_iniciado: false, regras_processo: false, andamento: false, finalizada: false });
        }
        if(resp.tempo_atual){
            clearTimeout(this.timer_timeout);
            this.seconds_diff = moment(resp.tempo_atual).diff(moment(), 'seconds');
            this.last_date = moment().format('YYYY-MM-DD HH:mm:ss');
            this.incTimer();
        }
        


    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    salvar_log_full_screen(event) {
        let event_m = '';
        if (event == 'exit_fullscreen') {
            event_m = 'exit_fullscreen';
            if (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') {
                if (this.props.socket != null) {

                    this.props.socket.emit("send_warning_behavior", { processo_id:this.props.processo.id,msg: 'O candidato saiu da tela cheia', sala_id: this.props.sala.id, aluno_id: this.props.user.id, nome_chat: this.props.user.name, id_msg: this.uuidv4(), faculdade_id: this.props.faculdade.id, type: 'in' });
                }

            }
        }
        else if (event == 'enter_fullscreen') {
            event_m = 'enter_fullscreen';
            if (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') {
                if (this.props.socket != null) {

                    this.props.socket.emit("send_warning_behavior", { processo_id:this.props.processo.id,msg: 'O candidato entrou em tela cheia', sala_id: this.props.sala.id, aluno_id: this.props.user.id, nome_chat: this.props.user.name, id_msg: this.uuidv4(), faculdade_id: this.props.faculdade.id, type: 'in' });
                }
            }
        }
        else return;


        fetch(`${URL}api/salvar_log?screen=${event_m}&processo_id=${this.props.processo.id}&processo_name=${this.props.processo.name}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {

                }
            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }


    event_fullscreen(e) {
        if (this.state.andamento == true && this.state.loading_screen == false && this.props.processo != null && this.props.processo.full_screen == true && this.props.processo.simulado == false) {
            this.salvar_log_full_screen(e);
        }
    }

    request_validation() {
        this.setState({loading_access:true,message_access:'Enviando pedido de liberação...'});
       
        fetch(`${URL}api/request_validation`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                processo_id:this.props.processo.id
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                else if (resp.message == "Server Error") {
                    this.request_access_timeout = setTimeout(() => {
                        this.request_validation();
                    }, 5000);
                }
                else {
                    // this.setState({loa})
                    let data={};
                    if(this.state.request_validation?.updated_at==undefined ||  
                        this.state.request_validation.updated_at<resp.request_validation.updated_at
                    ){
                        data.request_validation = resp.request_validation;
                        if(!this.need_delete_validation.includes(resp.request_validation?.id)){
                            this.need_delete_validation.push(resp.request_validation?.id)
                        }
                    }
                    this.setState({message_access:'Esperando decisão do fiscal...',...data});
                }
            } catch (err) {
                console.log(err);
                
            }

        })
            .catch((err) => {
                console.log(err);
        this.setState({loading_access:false});
                
            });
    }

    get_time_server() {
       
        fetch(`${URL}api/get_time_server`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                else if (resp.message == "Server Error") {
                    this.timeout_timeserver = setTimeout(() => {
                        this.get_time_server();
                    }, 5000);
                }
                else {
                    if (resp.atual != null) {
                        this.seconds_diff = moment(resp.atual).diff(moment(), 'seconds');
                        this.props.changeState({ tempo_atual: resp.atual });
                    }
                }
            } catch (err) {
                console.log(err);
                
            }

        })
            .catch((err) => {
                console.log(err);
                this.timeout_timeserver = setTimeout(() => {
                    this.get_time_server();
                }, 5000);
            });
    }

    send_message(texto) {
        let uid_msg = this.uuidv4();
        let msg = texto;
        if (msg != null && msg != '' && msg.replace(/ /g, '') != '' && this.props.socket != null && msg?.length < 1000) {
            this.props.socket.emit("send_msg_fiscal", { processo_id: this.props.processo.id, msg: msg, sala_id: this.props.sala.id, aluno_id: this.props.user.id, nome_chat: this.props.user.name, id_msg: uid_msg, faculdade_id: this.props.faculdade.id });
            // $(this).val('');
            // $('#body_msg').append(template_send.replace(/%MSG%/g,msg).replace(/%ID%/g,uid_msg));
            // $('#body_msg').scrollTop($('#body_msg')[0].scrollHeight);
            let mensagens = [...this.props.mensagens];
            mensagens.push({
                type_message: 'message', sender: { id: this.props.user.id, name: this.props.user.name, }, sender_id: this.props.user.id,
                receive_id: null, sala_id: this.props.sala.id, uuid: uid_msg, visto: undefined, message: msg
            });
            this.props.mudarMensagens(mensagens);

            return true;

        }
        return false;
    }

    calculateValue(){
        let value = 60;
        if(this.props.face_detected==true || this.state.request_validation?.approved==true){
            value+= 20;
        }
        if((this.props.left_movement==true && this.props.center_movement && this.props.right_movement) || this.state.request_validation?.approved==true){
            value+= 10;
        }
        if((this.props.audio_detected==true) || this.state.request_validation?.approved==true){
            value+= 10;
        }

        return value;
    }

    incTimer() {
        // var currentMinutes = Math.floor(totalSecs / 60);
        let last_date_new = moment().format('YYYY-MM-DD HH:mm:ss');

        let diff = moment().tz('America/Sao_Paulo').diff(this.last_date, 'seconds');
        this.last_date = last_date_new;

        let before_second_diff=this.seconds_diff;
        console.log(this.seconds_diff)

        if (Math.abs(diff) >= 2) {
            // console.log('Tempo');
            // console.log(seconds_diff);

            // console.log('Diff');
            // console.log(diff);

            this.seconds_diff = this.seconds_diff + (-1 * diff) + 1;

            // console.log('Tempo new');
            // console.log(seconds_diff);

        }
        console.log(this.seconds_diff)

        var totalSecs = moment(this.props.processo.inicio, 'YYYY-MM-DD HH:mm:ss').diff(moment().add(this.seconds_diff, 'seconds').format('YYYY-MM-DD HH:mm:ss'), 'seconds');

        // console.log($('#inicio_id').val())
        // console.log(moment().tz('America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss'))
        // var totalSecs = tempo - iniciado;
        var currentHours = Math.floor((totalSecs / 60) / 60);
        var currentMinutes = Math.floor(totalSecs / 60) - currentHours * 60

        var currentSeconds = totalSecs % 60;
        if (currentSeconds <= 9) currentSeconds = "0" + currentSeconds;
        if (currentMinutes <= 9) currentMinutes = "0" + currentMinutes;
        if (currentHours <= 9) currentHours = "0" + currentHours;

        // totalSecs--;
        console.log(totalSecs)
        let data={}
        if (totalSecs <= this.props.processo.chat_time && this.state.chat_show == false) {
            data.chat_show=true;
        }
        if (totalSecs <= this.props.processo.request_time && this.state.show_btn_validation_timer == false) {
            console.log('entrou')
            data.show_btn_validation_timer=true;
        }
        else{
            if(totalSecs%30==0 && this.state.show_btn_validation_timer==false){
                this.get_time_server()
            }
        }
        if(Object.keys(data).length!=0){
            this.setState(data);
        }
        // if(document.getElementById('total_secs')!=null){
        //     try{
        //         document.getElementById('total_secs').innerText=totalSecs+'_'+before_second_diff+'_'+this.seconds_diff;
        //     }
        //     catch(e){

        //     }
        // }
        if (totalSecs <= 0) {
            totalSecs = 0;
            this.iniciado=true;
        }
        else {


            this.timer_timeout = setTimeout(function () {
                this.incTimer()
            }.bind(this), 1000);
        }
    }


    renderTips(){
        if(this.props.processo.face_validation==true){
            if(this.props.loading_media){
                return (<div className='blink' style={{textAlign:'center',padding:10,textTransform:'uppercase',color:'darkgoldenrod',fontWeight:'bold',fontSize:18,border:'1px dashed',borderRadius:10,marginTop:20,marginBottom:20,display:'flex',justifyContent:'center',alignItems:'center'}}><CircularProgress style={{marginRight:5}} size={18} color='inherit'/> <span>Carregando informações...</span></div>)
            }
            else if(this.props.face_detected==false && this.props.multipleface_detected==false){
                console.log('nenhum rosto');
                return (<p className='blink' style={{textAlign:'center',padding:10,textTransform:'uppercase',color:'darkgoldenrod',fontWeight:'bold',fontSize:18,border:'1px dashed',borderRadius:10,marginTop:20,marginBottom:20}}>Nenhum rosto detectado. Mude a posição da câmera ou altere o dispositivo.</p>)
            }
            else if(this.props.face_detected==false && this.props.multipleface_detected==true){
                return (<p className='blink' style={{textAlign:'center',padding:10,textTransform:'uppercase',color:'darkgoldenrod',fontWeight:'bold',fontSize:18,border:'1px dashed',borderRadius:10,marginTop:20,marginBottom:20}}>Mais de um rosto detectado. Para entrar na avaliação você deve estar em um ambiente sozinho.</p>)
            }
            else if(this.props.center_movement==false){
                return (<p className='blink' style={{textAlign:'center',padding:10,textTransform:'uppercase',color:'darkgoldenrod',fontWeight:'bold',fontSize:18,border:'1px dashed',borderRadius:10,marginTop:20,marginBottom:20}}>Centralize o rosto na câmera para ser detectado</p>)
            }
            else if(this.props.right_movement==false){
                return (<p className='blink' style={{textAlign:'center',padding:10,textTransform:'uppercase',color:'darkgoldenrod',fontWeight:'bold',fontSize:18,border:'1px dashed',borderRadius:10,marginTop:20,marginBottom:20}}>Vire seu rosto para o lado direito</p>)
            }
            else if(this.props.left_movement==false){
                return (<p className='blink' style={{textAlign:'center',padding:10,textTransform:'uppercase',color:'darkgoldenrod',fontWeight:'bold',fontSize:18,border:'1px dashed',borderRadius:10,marginTop:20,marginBottom:20}}>Vire seu rosto para o lado esquerdo</p>)
            }
            else if(this.props.audio_detected==false){
                return (<p className='blink' style={{textAlign:'center',padding:10,textTransform:'uppercase',color:'darkgoldenrod',fontWeight:'bold',fontSize:18,border:'1px dashed',borderRadius:10,marginTop:20,marginBottom:20}}>Fale alto a frase "Estou aqui" para validar seu microfone</p>)
            }
            else {
                return (<p className='blink_success' style={{textAlign:'center',padding:10,textTransform:'uppercase',color:'green',fontWeight:'bold',fontSize:18,border:'1px dashed',borderRadius:10,marginTop:20,marginBottom:20}}>Validação de dispositivo concluída - Clique no botão abaixo para prosseguir</p>)
            }
        }
        else{
            return '';
        }
    }

    access_exam(){
        this.validation = true;
        this.get_time_server()
        if(this.props.socket!=null && this.need_delete_validation.length>0){
            this.props.socket.emit('request_validation_delete', { user_id: this.props.user.id, processo_id: this.props.processo.id,sala_id:this.props.sala.id })
            this.timeout_request_delete = setInterval(()=>{
                this.props.socket.emit('request_validation_delete', { user_id: this.props.user.id, processo_id: this.props.processo.id,sala_id:this.props.sala.id })
            },15000);
        }
        this.salvar_log_permission('Validação facial','face_validated')
        this.props.changeStateParent({show_btn_change:false})
        this.setState({need_face_validation:false,show_warning_approved:false,show_btn_validation:false},()=>{
            if (this.clicked == false && this.props.processo.full_screen == true) {
                this.clicked = true;
                this.openFullscreen(document.body);
                this.event_fullscreen('enter_fullscreen');
            }
        });
        
    }


    render() {//
        return (
            <div className="row" onClick={()=>{
  document.body.style.userSelect='text';
            }}  onCopy={(e)=>{
                e.preventDefault();
            }}
            onCut={(e) => e.preventDefault()}
            onDrop={(e) => e.preventDefault()}
            onChange={(e) => e.preventDefault()}

            >
                {this.props.user?.accessibility==true && this.props.user?.type_supervisor!='none' && <StyleQuestion />}
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">
                            <div hidden id="total_secs"></div>

                            <h4 className="mt-0 header-title" id='titulo_processo' style={{ fontSize: '1.5rem', color: 'black' }}>{this.props.processo.name}</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Nome do candidato: {this.props.user.name}</p>

                            {this.props.processo.type_time == 'all' && <>
                                <p className="m-b-20" style={{ color: 'black', marginBottom: '0.4rem' }}><b>Hora de início: </b>
                                    {moment(this.props.processo.inicio).format('DD/MM/YYYY HH:mm')}
                                </p>

                                <p className="m-b-20" style={{ color: 'black', marginBottom: '0.4rem' }}><b>Hora de encerramento: </b>
                                    {moment(this.props.processo.fim).format('DD/MM/YYYY HH:mm')}
                                </p>
                            </>}

                            {this.props.processo.type_time == 'restrict' && this.props.processo.inicio_absoluto == null && <>
                                <p className="m-b-20" style={{ color: 'black', marginBottom: '0.4rem' }}><b>Hora de início do processo: </b>
                                    {moment(this.props.processo.inicio).format('DD/MM/YYYY HH:mm')}
                                </p>

                                <p className="m-b-20" style={{ color: 'black', marginBottom: '0.4rem' }}><b>Hora de encerramento do processo: </b>
                                    {moment(this.props.processo.fim).format('DD/MM/YYYY HH:mm')}
                                </p>

                                <p className="m-b-20" style={{ color: 'black', marginBottom: '0.4rem' }}><b>Iniciado em: </b>
                                    -
                                </p>

                                <p className="m-b-20" style={{ color: 'black', marginBottom: '0.4rem' }}><b>Finaliza em: </b>
                                    -
                                </p>
                            </>}

                            {this.props.processo.type_time == 'restrict' && this.props.processo.inicio_absoluto != null && <>
                                <p className="m-b-20" style={{ color: 'black', marginBottom: '0.4rem' }}><b>Hora de início do processo: </b>
                                    {moment(this.props.processo.inicio_absoluto).format('DD/MM/YYYY HH:mm')}
                                </p>

                                <p className="m-b-20" style={{ color: 'black', marginBottom: '0.4rem' }}><b>Hora de encerramento do processo: </b>
                                    {moment(this.props.processo.fim_absoluto).format('DD/MM/YYYY HH:mm')}
                                </p>

                                <p className="m-b-20" style={{ color: 'black', marginBottom: '0.4rem' }}><b>Iniciado em: </b>
                                    {moment(this.props.processo.inicio).format('DD/MM/YYYY HH:mm:ss')}
                                </p>

                                <p className="m-b-20" style={{ color: 'black', marginBottom: '0.4rem' }}><b>Finaliza em: </b>
                                    {moment(this.props.processo.fim).format('DD/MM/YYYY HH:mm:ss')}
                                </p>
                            </>}

                            <hr />
                            {(this.state.regras_processo == true || this.state.andamento == true) && (this.state.need_face_validation==false || this.props.processo.face_validation==false)  && <div hidden={this.props.show_call && (this.props.user.accessibility==false || this.props.user.type_supervisor=='none' || this.props.processo.is_interview==true)} style={this.props.processo?.type_fiscalization == 'none' ? { bottom: 0, right: 0, top: 'unset' } : {}} id='timer'
                                className='timer_exam'>

                            </div>}
                            {this.state.loading_screen == false && <div>
                                {(this.state.need_face_validation==false || this.props.processo.face_validation==false) ? <>
                                <p className="m-b-20" style={{ color: 'black', whiteSpace: 'pre-line', fontSize: '1rem' }}><b style={{ fontWeight: 'bold' }}>Descrição da avaliação: </b>
                                    {"\n"}
                                    {this.props.processo.descricao}
                                </p>
                                <div hidden={this.state.full_screen == false && this.props.processo.full_screen == true}>


                                    {this.state.nao_iniciado == true && <NaoIniciada send_message={(m) => this.send_message(m)} goToRules={this.goToRules.bind(this)} get_processo={this.get_processo.bind(this)} {...this.props} />}
                                    {this.state.regras_processo == true && <AceiteRegras send_message={(m) => this.send_message(m)} aceitar_regras={this.aceitar_regras.bind(this)} get_processo={this.get_processo.bind(this)} {...this.props} />}
                                    {this.state.andamento == true && <AvaliacaoAndamento changeStateParent={(e)=>this.props.changeStateParent(e)} getValues={()=>this.props.getValues()} send_message={(m) => this.send_message(m)} aceitar_regras={this.aceitar_regras.bind(this)} get_processo={this.get_processo.bind(this)} {...this.props} />}
                                </div>
                                <div>
                                    {this.state.finish == true && <Finalizada changeStateParent={(e)=>this.props.changeStateParent(e)} get_processo={this.get_processo.bind(this)} {...this.props} />}
                                </div>

                                {this.props.show_call && <MeetingRoom
                        student={this.props.user}
                        full_screen={this.state.full_screen}
                        getAdminId={() => this.props.user.id}
                        send_message={(m) => this.send_message(m)}
                        sala_id={this.props.sala?.id}
                        faculdade_id={this.props.faculdade?.id}

                        processo={this.props.processo} closeCall={() => {
                            this.props.changeState({ show_call: false })
                        }}
                        // get_messages_from_student={(id) => this.props.mensagens}
                    />}

                                <div hidden={this.state.full_screen == true || this.state.finish == true || this.props.processo.full_screen == false}>
                                <p className='blink_success' style={{textAlign:'center',padding:10,textTransform:'uppercase',color:'green',fontWeight:'bold',fontSize:18,border:'1px dashed',borderRadius:10,marginTop:20,marginBottom:20}}>CLIQUE NO BOTÃO "ACESSAR AMBIENTE DE AVALIAÇÃO EM TELA CHEIA" PARA VOLTAR PARA A AVALIAÇÃO</p>

                                <div >
                                    <button onClick={() => {
                                        if (this.clicked == false) {
                                            this.clicked = true;
                                            this.openFullscreen(document.body);
                                            this.event_fullscreen('enter_fullscreen');
                                        }

                                        // this.setState({ full_screen: true });

                                    }} type="button" id="aceitar" className="btn btn-success btn-lg waves-effect waves-light" style={{ width: '100%' }}>ACESSAR AMBIENTE DE AVALIAÇÃO EM TELA CHEIA</button>

                                </div>
                                </div>

                                </>:<>
                                     <p style={{textAlign:'center',textTransform:'uppercase'}}><b>Complete todos os requisitos para avançar</b></p>
                                    <b style={{textTransform:'uppercase'}}>Requisitos para acesso:</b>
                                    <p style={{ marginBottom: 0, color: (this.props.stream != null) ? 'green' : 'red',textTransform:'uppercase',marginTop:5 }}><i className={(this.props.stream != null) ? 'fa fa-check' : 'mdi mdi-close'}></i> Compartilhamento de vídeo</p>
                                    <p style={{ marginBottom: 0, color: (this.props.stream != null) ? 'green' : 'red',textTransform:'uppercase',marginTop:5 }}><i className={(this.props.stream != null) ? 'fa fa-check' : 'mdi mdi-close'}></i> Compartilhamento de áudio</p>
                                    {this.props.processo.screen_sharing == true && <p style={{ marginBottom: 0, color: (this.props.stream_screen != null) ? 'green' : 'red',textTransform:'uppercase',marginTop:5 }}><i className={(this.props.stream_screen != null) ? 'fa fa-check' : 'mdi mdi-close'}></i> Compartilhamento de tela cheia</p>}
                                    {this.props.processo.face_validation && <>
                                    <p style={{ marginBottom: 0, color: (this.props.stream != null && this.props.face_detected==true) ? 'green' : 'red',textTransform:'uppercase',marginTop:5 }}><i className={(this.props.stream != null && this.props.face_detected==true) ? 'fa fa-check' : 'mdi mdi-close'}></i> Rosto detectado</p>
                                    <p style={{ marginBottom: 0, color: (this.props.stream != null && this.props.left_movement==true && this.props.right_movement==true && this.props.center_movement==true) ? 'green' : 'red',textTransform:'uppercase' ,marginTop:5}}><i className={(this.props.stream != null && this.props.left_movement==true && this.props.right_movement==true && this.props.center_movement==true) ? 'fa fa-check' : 'mdi mdi-close'}></i> Movimentações</p>
                                    <p style={{ marginBottom: 0, color: (this.props.stream != null && this.props.audio_detected==true) ? 'green' : 'red',textTransform:'uppercase',marginTop:5 }}><i className={(this.props.stream != null && this.props.audio_detected==true) ? 'fa fa-check' : 'mdi mdi-close'}></i> Microfone validado</p>
                                    
                                    </>}
                                    <ProgressBar striped variant="info" animated label={"PROGRESSO: "+(this.calculateValue())+'% - SIGA AS INSTRUÇÕES ABAIXO'} style={{height:50,marginTop:20,fontSize:18}} now={this.calculateValue()} max={100}  />
                                    {this.renderTips()}
                                    {this.props.connect == false && this.props.socket!=null && <div>
                    <p style={{ fontSize: '1.2rem', color: 'red', textAlign: 'center',textTransform:'uppercase' }}>Sua conexão está instável...</p>
                </div>}
                                    {this.props.processo.simulado==false && this.state.show_btn_validation_timer==true && this.state.show_btn_validation==true && this.state.loading_access==false && this.calculateValue()!=100 && <button onClick={() => {
                                        // this.get_time_server()
                                        if(this.props.connect==true){
                                            this.request_validation();
                                        }
                                        // this.setState({need_face_validation:false});
                                    }} type="button" disabled={this.props.connect==false}  className="btn btn-warning btn-lg waves-effect waves-light" style={{ width: '100%',marginTop:10 }}>CASO PRECISE DE AJUDA PARA A DETECÇÃO DE ROSTO, CLIQUE AQUI PARA PEDIR LIBERAÇÃO AO FISCAL</button>}
                                     {this.props.processo.simulado==false && this.state.show_btn_validation!=true && this.state.loading_access==false && this.calculateValue()!=100 && <p style={{textAlign:'center',marginTop:10,fontSize:16}}>CASO OCORRA ALGUM PROBLEMA DE DETECÇÃO, AGUARDE...</p>}
                                    
{this.props.processo.simulado == false && this.state.chat_show == true && (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && this.props.processo.chat_type != 'none' && <Chat 
                send_message={(m)=>this.send_message(m)}
                
                />}
                {this.state.loading_access==true && this.calculateValue()!=100 && <div style={{textAlign:'center',padding:10,textTransform:'uppercase',color:'darkgoldenrod',fontWeight:'bold',fontSize:18,border:'1px dashed',borderRadius:10,marginTop:10,marginBottom:10,display:'flex',justifyContent:'center',alignItems:'center'}}><CircularProgress style={{marginRight:5}} size={18} color='inherit'/> <span>{this.state.message_access}</span></div>}

                                   {this.calculateValue()==100 && <button onClick={() => {
                                        this.access_exam();
                                    }} type="button"  className="btn btn-success btn-lg waves-effect waves-light" style={{ width: '100%',marginTop:10 }}>CLIQUE AQUI PARA ACESSAR A AVALIAÇÃO</button>}
{this.props.processo.simulado == false && this.state.chat_show == true && (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && this.props.processo.chat_type != 'none' && <Chat 
                send_message={(m)=>this.send_message(m)}
                
                />}
                        <SweetAlert
                                success
                                title={"Aprovada!"}
                                onConfirm={() => {
                                    this.access_exam();
                                }}
                                // onCancel={this.onCancel.bind(this)}
                                show={this.state.show_warning_approved}
                                confirmBtnText='Acessar avaliação'
                                cancelBtnText='Cancelar'
                                confirmBtnBsStyle="info"
                                // cancelBtnBsStyle="success"
                                showCancel={false}
                            >
                                {/* Questão respondida com sucesso */}
                                Sua solicitação de liberação foi aprovada pelo fiscal
                            </SweetAlert>
                        <SweetAlert
                            danger
                            title={"Recusada!"}
                            onConfirm={() => this.setState({ show_warning_decline: false })}
                            // onCancel={this.onCancel.bind(this)}
                            show={this.state.show_warning_decline}
                            confirmBtnText='Ok'
                            cancelBtnText='Cancelar'
                            confirmBtnBsStyle="info"
                            // cancelBtnBsStyle="success"
                            showCancel={false}
                        >
                            Sua solicitação de liberação foi recusada pelo fiscal. Verifique se sua câmera está mostrando uma imagem nítida e se sua conexão está estável.
                        </SweetAlert>

                
                                </>}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <h6 style={{ textAlign: 'center' }}>Carregando dados. Por favor, aguarde.</h6>
                            </div>

                            </div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
const mapsStateToProps = (state) => (
    {
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
        token: state.AppReducer.token,
        stream: state.AppReducer.stream,
        stream_screen: state.AppReducer.stream_screen,

        processo_resultado: state.AppReducer.processo_resultado,
        // worker: state.AppReducer.worker,
        sala: state.AppReducer.sala,
        faculdade: state.AppReducer.faculdade,
        show_call: state.AppReducer.show_call,
        mensagens: state.AppReducer.mensagens,

        socket: state.AppReducer.socket,
        connect: state.AppReducer.connect,
        request_user: state.AppReducer.request_user,


    }
);

export default connect(mapsStateToProps, { changeState, logout,mudarMensagens })(ProcessoUser);


