import React, { useEffect, useRef, useState } from 'react';
import Routes from './routes';
import {useSelector, useDispatch} from 'react-redux'
import { URL } from './variables';
import { logout } from './actions/AppActions';


function App()  {
    const [loading,setLoading]=useState(true);
    const dispatch=useDispatch();
    
    
    const intervalRef = useRef();


    const get_token = (id_url)=>{
        fetch(`${URL}api/integration/auth/validate_token`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                id_url:id_url
            })
        }).then(async(response)=>{
            let resp = await response.json();
            if(resp.message == 'Server Error'){
                setTimeout(()=>{
                    get_token(id_url)
                },5000);
                return;
            }
            else if(resp.status==true){
                localStorage.setItem('token',resp.token)
                dispatch({type:'login',payload:{token:resp.token,user:{}}});

            }
            else{
                
                dispatch(logout())
                // window?.history?.back();

            }
            setLoading(false);
        }).catch((e)=>{
            setTimeout(()=>{
                get_token(id_url)
            },5000);
        })
    }
    useEffect(()=>{
        intervalRef.current = setInterval(()=>{
            document.getElementsByTagName('html')[0].childNodes.forEach((elem)=>{
                if(elem.nodeName!='BODY' && elem.nodeName!='HEAD' && elem.nodeName!='TEXT'){
                    elem.remove();
                }
            })
        },1000);


        const params = new URLSearchParams(window.location.search) // id=123
        let token = params.get('token') // 123 
        console.log(token)
        if(token==null){
            token = localStorage.getItem('token');
            dispatch({type:'login',payload:{token:token,user:{}}});
            setLoading(false);

        }
        else{
            get_token(token)
        }

        return ()=>{
            clearInterval(intervalRef.current)
        }
    },[])

    return (
        loading==false ? <Routes />:<div className="col-12 d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
          <h4 style={{ textAlign: 'center' }}>Carregando dados. Por favor, aguarde.</h4>
        </div>
      </div>
    );
}

export default App;
